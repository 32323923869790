import api from "../../services/api";

const useDashboard = () => {
    const find = async (params) => {
        const { data } = await api.request({
            url: `/dashboard`,
            method: 'GET',
            params
        });
        return data;
    };

    const channel = async (companyId, channel) => {
        const { data } = await api.request({
            url: `/dashconnections`,
            method: 'GET',
            params: { channel }
        });
        return data;
    };

    return {
        find,
        channel
    };
};

export default useDashboard;
