import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
    tag: {
        border: '1px solid #14c4c1',
        backgroundColor: 'transparent',
        color: '#14c4c1',
        marginRight: 1,
        padding: 1,
        fontWeight: 'bold',
        borderRadius: 5,
        marginTop: 1,
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: "0.8em",
        whiteSpace: "nowrap"
    }
}));

const ContactTag = ({ tag }) => {
    const classes = useStyles();

    return (
        <div className={classes.tag}
            style={{
                border: `1px ${tag.color}99`,
                backgroundColor: `${tag.color}99`,
                color: "#111111",
                borderRadius: 5,
                paddingLeft: 8,
                paddingRight: 8,
            }}
        >
            {tag?.name?.toUpperCase()}
        </div>
    )
}

export default ContactTag;