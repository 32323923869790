// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edgebutton {
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
    justify-content: center;
    display: flex;
    align-self: center;
    align-items: center;
  }
  
  .edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }
  
  .edgebutton-foreignobject body {
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
  }

  .react-flow__edge-path{
    stroke-width: 6px;
  }

  .react-flow__edge.selected .react-flow__edge-path{
    stroke: #0000FF
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/FlowBuilderConfig/nodes/css/buttonedge.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,2CAA2C;EAC7C;;EAEA;IACE,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE;EACF","sourcesContent":[".edgebutton {\n    width: 20px;\n    height: 20px;\n    background: #eee;\n    border: 1px solid #fff;\n    cursor: pointer;\n    border-radius: 50%;\n    font-size: 12px;\n    line-height: 1;\n    justify-content: center;\n    display: flex;\n    align-self: center;\n    align-items: center;\n  }\n  \n  .edgebutton:hover {\n    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);\n  }\n  \n  .edgebutton-foreignobject body {\n    background: transparent;\n    width: 40px;\n    height: 40px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 40px;\n  }\n\n  .react-flow__edge-path{\n    stroke-width: 6px;\n  }\n\n  .react-flow__edge.selected .react-flow__edge-path{\n    stroke: #0000FF\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
