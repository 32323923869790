import React, { useState, useEffect } from 'react';
import { TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      //default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#555555',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h5: {
      fontWeight: 500,
    },
  },
});

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '2rem',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  padding: '2rem',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}));

const Header = styled(Typography)(({ theme }) => ({
  marginBottom: '1rem',
  color: theme.palette.primary.main,
}));

const InputContainer = styled('div')({
  display: 'flex',
  width: '100%',
  marginBottom: '1rem',
});

const StyledTextField = styled(TextField)({
  flexGrow: 1,
  marginRight: '1rem',
});

const ListContainer = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: '1rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '8px',
  padding: '1rem',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: '1rem 0', // Adjusted margin for clearer separation
  padding: '1rem',
  borderRadius: '8px',
  backgroundColor: theme.palette.grey[200], // Slightly darker background for better separation
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s, background-color 0.3s',
  '&:hover': {
    transform: 'scale(1.02)',
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: '0.5rem',
  color: theme.palette.secondary.main,
}));

const ToDoList = () => {
  const [task, setTask] = useState('');
  const [tasks, setTasks] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);

  useEffect(() => {
    const savedTasks = localStorage.getItem('tasks');
    if (savedTasks) {
      setTasks(JSON.parse(savedTasks));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  const handleTaskChange = (event) => {
    setTask(event.target.value);
  };

  const handleAddTask = () => {
    if (!task.trim()) {
      // Impede que o usuário crie uma tarefa sem texto
      return;
    }

    const now = new Date();
    if (editIndex >= 0) {
      // Editar tarefa existente
      const newTasks = [...tasks];
      newTasks[editIndex] = { text: task, updatedAt: now, createdAt: newTasks[editIndex].createdAt };
      setTasks(newTasks);
      setTask('');
      setEditIndex(-1);
    } else {
      // Adicionar nova tarefa
      setTasks([...tasks, { text: task, createdAt: now, updatedAt: now }]);
      setTask('');
    }
  };

  const handleEditTask = (index) => {
    setTask(tasks[index].text);
    setEditIndex(index);
  };

  const handleDeleteTask = (index) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    setTasks(newTasks);
  };

  return (
    <ThemeProvider theme={theme}>
      <Root>
        <Header variant="h5">Lista de Tarefas</Header>
        <InputContainer>
          <StyledTextField
            label="Nova tarefa"
            value={task}
            onChange={handleTaskChange}
            variant="outlined"
          />
          <Button variant="contained" color="primary" onClick={handleAddTask}>
            {editIndex >= 0 ? 'Salvar111' : 'Adicionar'}
          </Button>
        </InputContainer>
        <ListContainer>
          <List>
            {tasks.map((task, index) => (
              <StyledListItem key={index}>
                <ListItemText primary={task.text} secondary={new Date(task.updatedAt).toLocaleString()} />
                <ListItemSecondaryAction>
                  <StyledIconButton onClick={() => handleEditTask(index)}>
                    <Edit />
                  </StyledIconButton>
                  <StyledIconButton onClick={() => handleDeleteTask(index)}>
                    <Delete />
                  </StyledIconButton>
                </ListItemSecondaryAction>
              </StyledListItem>
            ))}
          </List>
        </ListContainer>
      </Root>
    </ThemeProvider>
  );
};

export default ToDoList;
