import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
  Badge,
  Collapse,
  List,
  Tooltip
} from "@mui/material";
import {
  MdDashboard,
  MdWhatsapp,
  MdSync,
  MdSettings,
  MdPeople,
  MdContactPhone,
  MdAccountTree,
  MdFlashOn,
  MdHelpOutline,
  MdNewReleases,
  MdCode,
  MdEvent,
  MdLocalOffer,
  MdEventAvailable,
  MdExpandLess,
  MdExpandMore,
  MdPerson,
  MdList,
  MdBuild,
  MdAnnouncement,
  MdForum,
  MdLocalAtm,
  MdRotateRight,
  MdAllInclusive,
  MdAttachFile,
  MdBlurCircular,
  MdDeviceHub,
  MdSchedule,
  MdQueue,
  MdAssessment,
  MdDescription
} from "react-icons/md";
import Avatar from "@material-ui/core/Avatar";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import { isArray } from "lodash";
import api from "../services/api";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ToDoList from "../pages/ToDoList/";
import toastError from "../errors/toastError";
//import { makeStyles } from "@mui/styles";
import usePlans from "../hooks/usePlans";
import { Campaign, ShapeLine, Webhook } from "@mui/icons-material";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  ListSubheader: {
    height: 26,
    marginTop: "-15px",
    marginBottom: "-10px",
  },
  listItem: {
    height: "44px",
    width: "auto",
    "&:hover $iconHoverActive": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },

  listItemText: {
    fontSize: "14px",
    color: theme.mode === "light" ? "#666" : "#FFF",
  },
  avatarActive: {
    backgroundColor: "transparent",
  },
  avatarHover: {
    backgroundColor: "transparent",
  },
  iconHoverActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    height: 36,
    width: 36,
    backgroundColor: theme.mode === "light" ? "rgba(120,120,120,0.1)" : "rgba(120,120,120,0.5)",
    color: theme.mode === "light" ? "#666" : "#FFF",
    // color: theme.mode === "light" ? theme.palette.primary.main : "#FFF",
    "&:hover, &.active": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.4rem",
    },
  },
  subheader: {
    position: 'relative',
    textAlign: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    margin: '5px',
    backgroundColor: 'transparent',
    height: '35px',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = (props) => {
  const classes = useStyles();
  const { drawerClose, collapsed } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user, handleLogout } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [openFilasSubmenu, setOpenFilasSubmenu] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showKanban, setShowKanban] = useState(false);
  const [showOpenAi, setShowOpenAi] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false);
  const history = useHistory();
  const [showSchedules, setShowSchedules] = useState(false);
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [showExternalApi, setShowExternalApi] = useState(false);
  const [showAutomation, setShowUseAutomation] = useState(false);
  const [showFlow, setShowUseFlow] = useState(false);

  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const { getPlanCompany } = usePlans();

  const [openFlowSubmenu, setOpenFlowSubmenu] = useState(false);
  const [flowHover, setFlowHover] = useState(false)
  const theme = useTheme();
  const location = useLocation();
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    async function fetchData() {
      const companyId = user.companyId;
      const planConfigs = await getPlanCompany(undefined, companyId);

      setShowCampaigns(planConfigs.plan.useCampaigns);
      setShowKanban(planConfigs.plan.useKanban);
      setShowOpenAi(planConfigs.plan.useOpenAi);
      setShowIntegrations(planConfigs.plan.useIntegrations);
      setShowSchedules(planConfigs.plan.useSchedules);
      setShowInternalChat(planConfigs.plan.useInternalChat);
      setShowExternalApi(planConfigs.plan.useExternalApi);
      setShowUseAutomation(planConfigs.plan.useAutomation);
      setShowUseFlow(planConfigs.plan.useFlow);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const isFlowbuilderRouteActive =
    location.pathname.startsWith("/phrase-lists")
  location.pathname.startsWith("/flowbuilders")

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);



  function ListItemLink(props) {
    const { icon, primary, to, className } = props;

    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          <RouterLink to={to} ref={ref} {...itemProps} />
        )),
      [to]
    );

    return (
      <li>
        <Tooltip title={collapsed ? primary : ""} placement="right">
          <ListItem button dense component={renderLink} className={className}>
            {icon ? <ListItemIcon>{React.cloneElement(icon, { size: 24 })}</ListItemIcon> : null}
            <ListItemText primary={primary} />
          </ListItem>
        </Tooltip>
      </li>
    );
  }

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  const handleClickLogout = () => {
    //handleCloseMenu();
    handleLogout();
  };

  const toggleSubMenu = (event) => {
    // Impedir que o evento se propague para o elemento pai
    event.stopPropagation(); // Esta linha é crucial para evitar a propagação
    setOpenFilasSubmenu((prev) => !prev);
  };

  const toggleCampaignSubMenu = (event) => {
    // Prevenir a propagação para o componente pai para evitar fechar o menu lateral
    event.stopPropagation();
    setOpenCampaignSubmenu((prev) => !prev);
  };

  return (
    <div onClick={drawerClose}>
      <Can
        role={user.profile}
        perform={"drawer-service-items:view"}
        style={{
          overflowY: "scroll",
        }}
        no={() => (
          <>
            <ListSubheader
              hidden={collapsed}
              style={{
                display: collapsed ? 'none' : 'flex',
                paddingLeft: '20px',
                backgroundColor: theme.palette.background.default,  // Ajuste o fundo conforme o tema
                color: theme.palette.text.primary,  // Ajusta a cor do texto conforme o tema
              }}
              className={classes.subheader}
              inset
              color="inherit"
            >
              {i18n.t("Atendimento")}
            </ListSubheader>
            <>

              <ListItemLink
                to="/tickets"
                primary={i18n.t("mainDrawer.listItems.tickets")}
                icon={<MdWhatsapp size={24} style={{ color: "#FF4081" }} />}
                collapsed={collapsed}
              />
              <ListItemLink
                to="/quick-messages"
                primary={i18n.t("mainDrawer.listItems.quickMessages")}
                icon={<MdFlashOn size={24} style={{ color: "#FFC107" }} />}
                collapsed={collapsed}
              />
              {showKanban && (
                <ListItemLink
                  to="/kanban"
                  primary="Kanban"
                  icon={<MdBlurCircular size={24} style={{ color: "#4CAF50" }} />}
                  collapsed={collapsed}
                />
              )}
              <ListItemLink
                to="/todolist"
                primary={i18n.t("mainDrawer.listItems.tasks")}
                icon={<MdEvent size={24} style={{ color: "#E91E63" }} />}
                collapsed={collapsed}
              />
              <ListItemLink
                to="/contacts"
                primary={i18n.t("mainDrawer.listItems.contacts")}
                icon={<MdContactPhone size={24} style={{ color: "#3F51B5" }} />}
                collapsed={collapsed}
              />
              {showSchedules && (
                <>
                  <ListItemLink
                    to="/schedules"
                    primary={i18n.t("mainDrawer.listItems.schedules")}
                    icon={<MdSchedule size={24} style={{ color: "#9C27B0" }} />}
                    collapsed={collapsed}
                  />
                </>
              )}
              <ListItemLink
                to="/tags"
                primary={i18n.t("mainDrawer.listItems.tags")}
                icon={<MdLocalOffer size={24} style={{ color: "#009688" }} />}
                collapsed={collapsed}
              />
              {showInternalChat && (
                <>
                  <ListItemLink
                    to="/chats"
                    primary={i18n.t("mainDrawer.listItems.chats")}
                    icon={
                      <Badge color="secondary" variant="dot" invisible={invisible}>
                        <MdForum size={24} style={{ color: "#FF5722" }} />
                      </Badge>
                    }
                    collapsed={collapsed}
                  />
                </>
              )}
              <ListItemLink
                to="/helps"
                primary={i18n.t("mainDrawer.listItems.helps")}
                icon={<MdHelpOutline size={24} style={{ color: "#03A9F4" }} />}
                collapsed={collapsed}
              />
            </>
          </>
        )}
      />

      <Can
        role={user.profile}
        perform={"drawer-admin-items:view"}
        yes={() => (
          <>
            <ListSubheader
              hidden={collapsed}
              style={{
                display: collapsed ? 'none' : 'flex',
                paddingLeft: '20px',
                backgroundColor: theme.palette.background.default,  // Ajuste o fundo conforme o tema
                color: theme.palette.text.primary,  // Ajusta a cor do texto conforme o tema
              }}
              className={classes.subheader}
              inset
              color="inherit"
            >
              {i18n.t("Gerência")}
            </ListSubheader>
            <ListItemLink
              small
              to="/"
              primary="Dashboard"
              icon={<MdDashboard size={24} style={{ color: "#FF4081" }} />}
              collapsed={collapsed}
            />
          </>
        )}
      />
      {/* FLOWBUILDER */}
      <Can
        role={user.profile}
        perform="dashboard:view"
        yes={() => (
          <>

          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            {
              process.env.REACT_APP_ENABLE_AUTOMATION_VAR === "true" && showAutomation && (
                <>
                  <ListSubheader
                    hidden={collapsed}
                    style={{
                      display: collapsed ? 'none' : 'flex',
                      paddingLeft: '20px',
                      backgroundColor: theme.palette.background.default,  // Ajuste o fundo conforme o tema
                      color: theme.palette.text.primary,  // Ajusta a cor do texto conforme o tema
                    }}
                    className={classes.subheader}
                    inset
                    color="inherit"
                  >
                    {i18n.t("mainDrawer.listItems.integration")}
                  </ListSubheader>
                  <ListItemLink
                    to="/integrations"
                    primary={i18n.t("mainDrawer.listItems.gerenciar")}
                    icon={<MdBuild size={24} style={{ color: "#FFC107" }} />}
                    collapsed={collapsed}
                  />
                </>
              )
            }

            <ListSubheader
              hidden={collapsed}
              style={{
                display: collapsed ? 'none' : 'flex',
                paddingLeft: '20px',
                backgroundColor: theme.palette.background.default,  // Ajuste o fundo conforme o tema
                color: theme.palette.text.primary,  // Ajusta a cor do texto conforme o tema
              }}
              className={classes.subheader}
              inset
              color="inherit"
            >
              {i18n.t("Administração")}
            </ListSubheader>
            <>
              <ListItem
                button
                onClick={toggleSubMenu} // Adicione onClick aqui para abrir submenu
              >
                {collapsed ? (
                  <Tooltip title={i18n.t("Filas e Conexões")} placement="right">
                    <ListItemIcon>
                      <MdAccountTree size={24} style={{ color: "#4CAF50" }} />
                    </ListItemIcon>
                  </Tooltip>
                ) : (
                  <ListItemIcon>
                    <MdAccountTree size={24} style={{ color: "#4CAF50" }} />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary="Filas e Conexões"
                  primaryTypographyProps={{ style: { fontSize: '0.875rem', textAlign: 'left' } }}
                />
                {openFilasSubmenu ? <MdExpandLess /> : <MdExpandMore />}
              </ListItem>
              <Collapse
                style={{ paddingLeft: 15 }}
                in={openFilasSubmenu}
                timeout="auto"
                collapsed={collapsed}
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItemLink
                    to="/connections"
                    primary={i18n.t("mainDrawer.listItems.connections")}
                    icon={
                      <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                        <MdSync size={24} style={{ color: "#FF4081" }} />
                      </Badge>
                    }
                    collapsed={collapsed}
                  />
                  {showFlow && (
                    <ListItemLink
                      to="/flowbuilders"
                      primary={i18n.t("Flowbuilder")}
                      icon={<Webhook size={24} style={{ color: "#4690DA" }} />}
                      collapsed={collapsed}
                    />
                  )}
                  {showOpenAi && (
                    <ListItemLink
                      to="/prompts"
                      primary={i18n.t("mainDrawer.listItems.prompts")}
                      icon={<MdAllInclusive size={24} style={{ color: "#03A9F4" }} />}
                      collapsed={collapsed}
                    />
                  )}
                  {showIntegrations && (
                    <ListItemLink
                      to="/queue-integration"
                      primary={i18n.t("mainDrawer.listItems.queueIntegration")}
                      icon={<MdDeviceHub size={24} style={{ color: "#FF5722" }} />}
                      collapsed={collapsed}
                    />
                  )}
                  <ListItemLink
                    to="/queues"
                    primary={i18n.t("Filas/Departamentos")}
                    icon={<MdQueue size={24} style={{ color: "#4CAF50" }} />}
                    collapsed={collapsed}
                  />
                </List>
              </Collapse>
            </>
            {process.env.REACT_APP_ENABLE_CAMPING === "true" && showCampaigns && (
              <>
                <ListItem
                  button
                  onClick={toggleCampaignSubMenu}
                  collapsed={collapsed}
                >                {collapsed ? (
                  <Tooltip title={i18n.t("mainDrawer.listItems.campaigns")} placement="right">
                    <ListItemIcon>
                      <MdEventAvailable size={24} style={{ color: "#4CAF50" }} />
                    </ListItemIcon>
                  </Tooltip>
                ) : (
                  <ListItemIcon>
                    <MdEventAvailable size={24} style={{ color: "#4CAF50" }} />
                  </ListItemIcon>
                )}

                  <ListItemText
                    primary={i18n.t("mainDrawer.listItems.campaigns")}
                    primaryTypographyProps={{ style: { fontSize: '0.875rem', textAlign: 'left' } }}
                    collapsed={collapsed}
                  />
                  {openCampaignSubmenu ? <MdExpandLess /> : <MdExpandMore />}
                </ListItem>
                <Collapse
                  style={{ paddingLeft: 15 }}
                  in={openCampaignSubmenu}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItemLink
                      to="/campaigns"
                      primary={i18n.t("Listagem")}
                      icon={<MdList size={24} style={{ color: "#E91E63" }} />}
                      collapsed={collapsed}
                    />
                    <ListItemLink
                      to="/contact-lists"
                      primary={i18n.t("Listas de Contatos")}
                      icon={<MdPerson size={24} style={{ color: "#3F51B5" }} />}
                      collapsed={collapsed}
                    />
                    <ListItemLink
                      to="/campaigns-config"
                      primary={i18n.t("Configurações")}
                      icon={<MdSettings size={24} style={{ color: "#009688" }} />}
                      collapsed={collapsed}
                    />
                  </List>
                </Collapse>
              </>
            )
            }
            {user.super && (
              <ListItemLink
                to="/announcements"
                primary={i18n.t("mainDrawer.listItems.annoucements")}
                icon={<MdAnnouncement size={24} style={{ color: "#9C27B0" }} />}
                collapsed={collapsed}
              />
            )}



            {/* <ListItemLink
              to="/files" 
              primary={i18n.t("mainDrawer.listItems.files")}
              icon={<MdAttachFile size={24} style={{ color: "#FFC107" }} />}
              collapsed={collapsed}
          />*/}

            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<MdPeople size={24} style={{ color: "#E91E63" }} />}
              collapsed={collapsed}
            />
            <ListItemLink
              to="/reports"
              primary={i18n.t("Relatórios")}
              icon={<MdDescription size={24} style={{ color: "#ACAF50" }} />}
              collapsed={collapsed}
            />


            {showExternalApi && (
              <>
                <ListItemLink
                  to="/messages-api"
                  primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                  icon={<MdCode size={24} style={{ color: "#03A9F4" }} />}
                  collapsed={collapsed}
                />
              </>
            )}
            {<ListItemLink
              to="/financeiro"
              primary={i18n.t("mainDrawer.listItems.financeiro")}
              icon={<MdLocalAtm size={24} style={{ color: "#009688" }} />}
            />}

            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<MdSettings size={24} style={{ color: "#FF4081" }} />}
              collapsed={collapsed}
            />
            { }

          </>
        )}
      />
      <ListSubheader
        hidden={collapsed}
        style={{
          display: collapsed ? 'none' : 'flex',
          paddingLeft: '20px',
          backgroundColor: theme.palette.background.default,  // Ajuste o fundo conforme o tema
          color: theme.palette.text.primary,  // Ajusta a cor do texto conforme o tema
        }}
        className={classes.subheader}
        inset
        color="inherit"
      >
        {i18n.t("Logout")}
      </ListSubheader>
      <li>
        <Tooltip title={collapsed ? `${i18n.t("mainDrawer.appBar.user.logout")}` : ""} placement="right">
          <ListItem
            button
            dense
            onClick={handleClickLogout}>
            <ListItemIcon><MdRotateRight size={24} style={{ color: "#FFC107" }} /></ListItemIcon>
            <ListItemText primary={i18n.t("mainDrawer.appBar.user.logout")} />
          </ListItem>
        </Tooltip>
      </li>
    </div>
  );
};

export default MainListItems;
