import React, { useState, useEffect } from "react";
import { Modal, TextField, Button, makeStyles, Typography, MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4),
        boxShadow: theme.shadows[5],
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        minWidth: 300,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
    },
    formControl: {
        minWidth: 120,
    },
}));

const EditInvoiceModal = ({ open, onClose, invoice, onSave }) => {
    const classes = useStyles();

    // Função para calcular o status da fatura
    const getInvoiceStatus = (invoice) => {
        const hoje = moment().format("DD/MM/yyyy");
        const vencimento = moment(invoice.dueDate).format("DD/MM/yyyy");
        const diff = moment(vencimento, "DD/MM/yyyy").diff(moment(hoje, "DD/MM/yyyy"));
        const dias = moment.duration(diff).asDays();
        const status = invoice.status;

        if (status === "paid") {
            return "Pago";
        }
        if (dias < 0) {
            return "Vencido";
        } else {
            return "Em Aberto";
        }
    };

    // Inicializar com os valores da fatura somente quando `invoice` estiver disponível
    const [formData, setFormData] = useState({
        status: invoice?.status || "",
        value: invoice?.value ? Number(invoice.value).toFixed(2) : "", // Garantir duas casas decimais
        dueDate: invoice?.dueDate ? moment(invoice.dueDate).format("YYYY-MM-DD") : "", // Formatar para input date
        detail: invoice?.detail || ""
    });

    useEffect(() => {
        // Atualizar o estado quando a `invoice` mudar
        if (invoice) {
            setFormData({
                status: invoice.status || "",
                value: invoice?.value ? Number(invoice.value).toFixed(2) : "", // Garantir duas casas decimais
                dueDate: invoice.dueDate ? moment(invoice.dueDate).format("YYYY-MM-DD") : "",
                detail: invoice.detail || ""
            });
        }
    }, [invoice]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = () => {
        // Validar data de vencimento
        if (moment(formData.dueDate).isBefore(moment().startOf('day'))) {
            alert('A data de vencimento não pode ser anterior à data atual.');
            return;
        }

        onSave({ ...formData, id: invoice.id });
    };

    if (!invoice) {
        return null; // Não renderizar nada se `invoice` for null
    }

    return (
        <Modal open={open} onClose={onClose}>
            <div className={classes.modalContent}>
                <Typography variant="h6">Editar Fatura</Typography>
                <Typography variant="subtitle1">
                    Empresa: {invoice.companyName} <br />
                    Vencimento: {moment(invoice.dueDate).format("DD/MM/YYYY")} <br />
                    Status: {getInvoiceStatus(invoice)}
                </Typography>

                {/* Campo de Seleção para Status */}
                <FormControl className={classes.formControl}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                    >
                        {/* Mostrar opções de acordo com o status atual */}
                        <MenuItem value="open">Aberto</MenuItem>
                        <MenuItem value="paid">Pago</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    label="Valor"
                    name="value"
                    type="number"
                    inputProps={{ step: "0.01" }} // Permitir números com ponto decimal
                    value={formData.value}
                    onChange={handleChange}
                />
                <TextField
                    label="Data de Vencimento"
                    name="dueDate"
                    type="date"
                    value={formData.dueDate}
                    onChange={handleChange}
                    InputProps={{
                        inputProps: { min: moment().format("YYYY-MM-DD") }, // Impedir datas anteriores à data atual
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Detalhes"
                    name="detail"
                    value={formData.detail}
                    onChange={handleChange}
                />
                <div className={classes.buttonContainer}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Salvar
                    </Button>
                    <Button variant="contained" onClick={onClose}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default EditInvoiceModal;
