import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green, grey, red, blue } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { v4 as uuidv4 } from "uuid";

import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";

import RoomIcon from '@material-ui/icons/Room';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AndroidIcon from "@material-ui/icons/Android";
import TelegramIcon from "@material-ui/icons/Telegram";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TicketMessagesDialog from "../TicketMessagesDialog";
import DoneIcon from '@material-ui/icons/Done';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import contrastColor from "../../helpers/contrastColor";
import ContactTag from "../ContactTag";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
    minHeight: '93px', // Colocando um valor mínimo, caso o conteúdo seja pequeno
    paddingBottom: "10px", // Adiciona um espaçamento no final
  },

  pendingTicket: {
    cursor: "unset",
    minHeight: '93px', // Altura mínima, mas poderá crescer
    paddingBottom: "10px", // Adiciona um espaçamento no final
  },

  queueTag: {
    background: "#FCFCFC",
    color: "#000",
    marginRight: 1,
    padding: 1,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: "0.8em",
    whiteSpace: "nowrap"
  },
  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  newMessagesCount: {
    position: "absolute",
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
    top: "23px",
    left: "20px",
    borderRadius: 0,
  },
  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },
  connectionTag: {
    border: '1px solid #14c4c1',
    backgroundColor: 'transparent',
    color: '#14c4c1',
    marginRight: 1,
    padding: 1,
    fontWeight: 'bold',
    borderRadius: 5,
    marginTop: 1,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.8em",
    whiteSpace: "nowrap", // Evitar quebra de linha dentro de uma tag
    textOverflow: 'ellipsis', // Caso o texto seja muito longo
  },
  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "5px",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    textAlign: "right",
    position: "relative",
    top: 26,
    fontSize: 9,
    marginLeft: 4,
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: "0%",
    width: "90%",
    marginLeft: "5px",
    overflow: "hidden", // Prevenir que o texto ultrapasse o limite
    textOverflow: "ellipsis", // Adiciona reticências caso o texto seja longo demais
    whiteSpace: "nowrap", // Prevenir quebra de linha no nome
  },


  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },

  acceptButton: {
    position: "absolute",
    right: "108px",
  },


  acceptButton: {
    position: "absolute",
    left: "50%",
  },

  smallIcon: {
    fontSize: '10px',
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  ticketInfo: {
    position: "relative",
    top: -13
  },
  secondaryContentSecond: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap', // Permitir que as tags quebrem para a próxima linha
    gap: '5px', // Adiciona espaçamento entre as tags
    maxWidth: '100%', // Garantir que respeite o tamanho máximo do container
    overflow: 'hidden', // Prevenir que ultrapasse o container
  },
  ticketInfo1: {
    position: "relative",
    top: 13,
    right: 0
  },
  Radiusdot: {
    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },

  }
}));
{/*PLW DESIGN INSERIDO O dentro do const handleChangeTab*/ }
const TicketListItemCustom = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [ticketUser, setTicketUser] = useState(null);
  const [ticketQueueName, setTicketQueueName] = useState(null);
  const [ticketQueueColor, setTicketQueueColor] = useState(null);
  const [tag, setTag] = useState([]);
  const [whatsAppName, setWhatsAppName] = useState(null);

  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useContext(AuthContext);
  const { profile } = user;

  useEffect(() => {
    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user?.name);
    }
    setTicketQueueName(ticket.queue?.name?.toUpperCase());
    setTicketQueueColor(ticket.queue?.color);

    if (ticket.whatsappId && ticket.whatsapp) {
      setWhatsAppName(ticket.whatsapp.name?.toUpperCase());
    }

    setTag(ticket?.tags);

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  {/*CÓDIGO NOVO SAUDAÇÃO*/ }
  const handleCloseTicket = async (id) => {
    setTag(ticket?.tags);
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
        queueId: ticket?.queue?.id,
        useIntegration: false,
        promptId: null,
        integrationId: null,
        lastFlowId: null,
        flowWebhook: false
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/`);
  };

  const handleReopenTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
        queueId: ticket?.queue?.id
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
        powerCrmUser: user?.userIdPowerCrm,
      });

      let settingIndex;

      try {
        const { data } = await api.get("/settings/");

        settingIndex = data.filter((s) => s.key === "sendGreetingAccepted");

      } catch (err) {
        toastError(err);

      }

      if (settingIndex[0].value === "enabled" && !ticket.isGroup) {
        handleSendMessage(ticket.id);

      }

    } catch (err) {
      setLoading(false);

      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }

    // handleChangeTab(null, "tickets");
    // handleChangeTab(null, "open");
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleSendMessage = async (id) => {

    const msg = `{{ms}} *{{name}}*, meu nome é *${user?.name}* e agora vou prosseguir com seu atendimento!`;
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: `*Mensagem Automática:*\n${msg.trim()}`,
    };
    try {
      await api.post(`/messages/${id}`, message);
    } catch (err) {
      toastError(err);

    }
  };
  {/*CÓDIGO NOVO SAUDAÇÃO*/ }

  const handleSelectTicket = (ticket) => {
    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
  };


  const renderTicketInfo = () => {
    if (ticketUser) {
      return (
        <>
          {ticket.chatbot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}

          {/* </span> */}
        </>
      );
    }
  };

  const renderTicketConnection = () => {
    return (
      <>
        {
          ticket.channel === "whatsapp" || ticket.channel === "whatsapp_business_account" && (
            <WhatsAppIcon fontSize="small" style={{ color: "#ffffff", fontSize: '14px' }} />
          )
        }
        {
          ticket.channel === "instagram" && (
            <InstagramIcon fontSize="small" style={{ color: "#ffffff", fontSize: '14px' }} />
          )
        }
        {
          ticket.channel === "facebook" && (
            <FacebookIcon fontSize="small" style={{ color: "#ffffff", fontSize: '14px' }} />

          )
        }
        {
          ticket.channel === "telegram" && (
            <TelegramIcon fontSize="small" style={{ color: "#ffffff", fontSize: '14px' }} />

          )
        }
      </>
    )
  }

  function getChannelStyle(channel) {
    switch (channel) {
      case 'whatsapp':
        return {
          backgroundColor: '#25D366', // Verde WhatsApp
          color: "#ffffff",
          border: '1px solid #25D366'
        };
      case 'whatsapp_business_account':
        return {
          backgroundColor: '#25D366', // Verde WhatsApp
          color: "#ffffff",
          border: '1px solid #25D366'
        };
      case 'facebook':
        return {
          backgroundColor: '#3b5998', // Azul Facebook
          color: "#ffffff",
          border: '1px solid #3b5998'
        };
      case 'instagram':
        return {
          backgroundColor: '#F56040',
          color: "#ffffff",
          border: '1px solid transparent' // O gradiente já é bastante distinto
        };
      case 'telegram':
        return {
          backgroundColor: '#24A1DE',
          color: "#ffffff",
          border: '1px solid transparent' // O gradiente já é bastante distinto
        };
    }
  }

  const channelStyle = getChannelStyle(ticket.channel)


  return (
    <React.Fragment key={ticket.id}>
      <TicketMessagesDialog
        open={openTicketMessageDialog}

        handleClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket.id}
      ></TicketMessagesDialog>
      <ListItem dense button
        onClick={(e) => {
          if (ticket.status === "pending") return;
          handleSelectTicket(ticket);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <Tooltip arrow placement="right" title={ticket.queue?.name.toUpperCase() || "SEM FILA"} >
          <span style={{
            backgroundColor: ticket.queue?.color || "#7C7C7C"
          }} className={classes.ticketQueueColor}></span>
        </Tooltip>
        <ListItemAvatar>
          {ticket.status !== "pending" ?
            <Avatar
              style={{
                // marginTop: "-20px",
                // marginLeft: "-3px",
                width: "55px",
                height: "55px",
                borderRadius: 10,
              }}
              src={ticket?.contact?.profilePicUrl}
            />
            :
            <Avatar
              style={{
                // marginTop: "-30px",
                // marginLeft: "0px",
                width: "55px",
                height: "55px",
                borderRadius: 10,
              }}
              src={ticket?.contact?.profilePicUrl}
            />
          }
        </ListItemAvatar>
        <ListItemText
          disableTypography

          primary={
            <span className={classes.contactNameWrapper}>
              <Typography
                noWrap
                component="span"
                variant="body2"
                color="textPrimary"
              >
                {ticket.contact?.name}
                {profile === "admin" && (
                  <Tooltip title="Espiar Conversa">
                    <VisibilityIcon
                      onClick={() => setOpenTicketMessageDialog(true)}
                      fontSize="small"
                      style={{
                        color: '#071A44',
                        cursor: "pointer",
                        marginLeft: 10,
                        verticalAlign: "middle"
                      }}
                    />
                  </Tooltip>
                )}
              </Typography>
              <ListItemSecondaryAction>
                <Box className={classes.ticketInfo1}>{renderTicketInfo()}</Box>
              </ListItemSecondaryAction>
            </span>

          }
          secondary={
            <span className={classes.contactNameWrapper}>
              <Typography
                className={classes.contactLastMessage}
                noWrap
                component="span"
                variant="body2"
                color="textSecondary"
              > {ticket.lastMessage?.includes('data:image/png;base64') ?
                <MarkdownWrapper> Localização</MarkdownWrapper> : <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>}
                <span className={classes.secondaryContentSecond} >
                  {ticket?.channel ? <Badge
                    style={{
                      border: `1px ${channelStyle.backgroundColor}`,
                      backgroundColor: channelStyle.backgroundColor,
                      color: "#111111",
                      borderRadius: 5,
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                    className={classes.connectionTag}>{renderTicketConnection()} - {ticket?.whatsapp?.name?.toUpperCase()}</Badge> :
                    <br></br>
                  }
                  {ticketUser ? <Badge
                    style={{
                      border: '1px #69bcf099',
                      backgroundColor: '#69bcf099',
                      color: "#111111",
                      borderRadius: 5,
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                    className={classes.connectionTag}>{ticketUser?.toUpperCase()}</Badge> : <br></br>}
                  <Badge style={{
                    border: `1px ${ticket.queue?.color || "#aeb458"}99`,
                    backgroundColor: `${ticket.queue?.color || "#aeb458"}99`,
                    borderRadius: 5,
                    color: "#111111",
                    paddingLeft: 8,
                    paddingRight: 8,
                  }} className={classes.connectionTag}>{ticket.queue?.name?.toUpperCase() || "SEM FILA"}
                  </Badge>
                </span>
                {ticket?.tags ?
                  <span style={{ paddingTop: "2px" }} className={classes.secondaryContentSecond} >
                    {tag?.map((tag) => {
                      return (
                        <ContactTag tag={tag} key={`ticket-contact-tag-${ticket.id}-${tag.id}`} />
                      );
                    })}
                  </span> :
                  <br></br>
                }
              </Typography>

              <Badge
                className={classes.newMessagesCount}
                badgeContent={ticket.unreadMessages}
                classes={{
                  badge: classes.badgeStyle,
                }}
              />
            </span>
          }

        />
        <ListItemSecondaryAction>

          <span className={classes.secondaryContentSecond} >
            {ticket.status === "pending" && (
              <ButtonWithSpinner
                //color="primary"
                style={{ backgroundColor: 'green', color: 'white', padding: '2px 2px 2px 2px', bottom: '17px', borderRadius: '7px', left: '8px', fontSize: '8px', marginLeft: isSameDay(parseISO(ticket.updatedAt), new Date()) ? -33 : -7, marginBottom: 7 }}
                variant="contained"
                className={classes.acceptButton}
                size="small"
                loading={loading}
                onClick={e => handleAcepptTicket(ticket.id)}
              >
                {i18n.t("ticketsList.buttons.accept").toLowerCase()}
              </ButtonWithSpinner>

            )}
            {(ticket.status !== "closed") && (
              <ButtonWithSpinner
                //color="primary"
                style={{ backgroundColor: 'red', color: 'white', padding: '2px 2px 2px 2px', bottom: '0px', borderRadius: '7px', left: '8px', fontSize: '8px', marginLeft: isSameDay(parseISO(ticket.updatedAt), new Date()) ? -33 : -7 }}
                variant="contained"
                className={classes.acceptButton}
                size="small"
                loading={loading}
                onClick={e => handleCloseTicket(ticket.id)}
              >
                {i18n.t("ticketsList.buttons.closed").toLowerCase()}
              </ButtonWithSpinner>

            )}
            {(ticket.status === "closed") && (
              <ButtonWithSpinner
                //color="primary"
                style={{ backgroundColor: 'red', color: 'white', padding: '2px 2px 2px 2px', bottom: '0px', borderRadius: '7px', left: '8px', fontSize: '8px', marginLeft: isSameDay(parseISO(ticket.updatedAt), new Date()) ? -33 : -7 }}
                variant="contained"
                className={classes.acceptButton}
                size="small"
                loading={loading}
                onClick={e => handleReopenTicket(ticket.id)}
              >
                {i18n.t("ticketsList.buttons.reopen").toLowerCase()}
              </ButtonWithSpinner>

            )}
          </span>
          {ticket.lastMessage && (
            <>

              <Typography
                className={classes.lastMessageTime}
                component="span"
                variant="body2"
                color="textSecondary"
              >

                {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                  <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                ) : (
                  <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                )}
              </Typography>

              <br />

            </>
          )}

        </ListItemSecondaryAction>
      </ListItem>

      <Divider variant="fullWidth" component="li" />
    </React.Fragment>
  );
};

export default TicketListItemCustom;