import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";
import api from "../../services/api";
import { styled } from "@mui/material/styles";
import backgroundImage from '../../assets/wa-background.png'; // Certifique-se de ajustar o caminho conforme necessário
import { isSameDay, parseISO, format } from "date-fns";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },
}));

const MainContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  flex: 1,
  overflow: "hidden",
  borderRadius: 0,
  height: "100%",
  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'auto',
}));

const MessageList = styled('div')(({ theme }) => ({
  position: "relative",
  overflowY: "auto",
  height: "100%",
  scrollbarWidth: "thin",
  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.paper}`,
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    border: `3px solid ${theme.palette.background.paper}`,
  },
}));

const InputArea = styled('div')({
  position: "relative",
  height: "auto",
  backgroundColor: '#f5f5f5',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  padding: '10px',
});

const StyledInput = styled(Input)(({ theme }) => ({
  padding: "10px",
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

const MessageBox = styled(Box)(({ theme, isSender }) => ({
  display: "flex",
  alignItems: "flex-start",
  padding: "10px 15px",
  margin: "10px",
  position: "relative",
  backgroundColor: isSender ? "#dcf8c6" : "#f9f9f9",
  maxWidth: '25%',
  borderRadius: 10,
  borderBottomLeftRadius: isSender ? 10 : 0,
  borderBottomRightRadius: isSender ? 0 : 10,
  color: "#303030",
  boxShadow: theme.shadows[2],
  flexDirection: isSender ? "row-reverse" : "row",
  alignSelf: isSender ? "flex-end" : "flex-start",
  marginLeft: isSender ? "auto" : "10px",
  marginRight: isSender ? "10px" : "auto",
}));

const MessageContent = styled('div')(({ isSender }) => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: isSender ? "10px" : "10px",
  marginRight: isSender ? "10px" : "10px",
  textAlign: isSender ? "right" : "left",
  marginBottom: "5px",
}));

export default function ChatMessages({
  chat,
  messages,
  handleSendMessage,
  handleLoadMore,
  scrollToBottomRef,
  pageInfo,
  loading,
}) {
  const { user } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const baseRef = useRef();
  const [contentMessage, setContentMessage] = useState("");

  const scrollToBottom = () => {
    if (baseRef.current) {
      baseRef.current.scrollIntoView({});
    }
  };

  const unreadMessages = (chat) => {
    if (chat !== undefined) {
      const currentUser = chat.users.find((u) => u.userId === user.id);
      return currentUser.unreads > 0;
    }
    return 0;
  };

  useEffect(() => {
    if (unreadMessages(chat) > 0) {
      try {
        api.post(`/chats/${chat.id}/read`, { userId: user.id });
      } catch (err) { }
    }
    scrollToBottomRef.current = scrollToBottom;
  }, []);

  const handleScroll = (e) => {
    const { scrollTop } = e.currentTarget;
    if (!pageInfo.hasMore || loading) return;
    if (scrollTop < 600) {
      handleLoadMore();
    }
  };
  const classes = useStyles();
  return (
    <MainContainer>
      <MessageList onScroll={handleScroll}>
        {Array.isArray(messages) &&
          messages.map((item, key) => (
            <MessageBox
              key={key}
              isSender={item.senderId === user.id}
            >
              <Avatar src={item.sender.avatarUrl} alt={item.sender.name} />
              <MessageContent isSender={item.senderId === user.id}>
                <Typography variant="subtitle2">{item.sender.name}:</Typography>
                <Typography variant="body2">{item.message}</Typography>
                <span className={classes.timestamp}>
                  {format(parseISO(item.createdAt), "HH:mm")}
                </span>
              </MessageContent>
            </MessageBox>
          ))}
        <div ref={baseRef}></div>
      </MessageList>
      <InputArea>
        <FormControl variant="outlined" fullWidth>
          <StyledInput
            multiline
            value={contentMessage}
            onKeyUp={(e) => {
              if (e.key === "Enter" && contentMessage.trim() !== "") {
                handleSendMessage(contentMessage);
                setContentMessage("");
              }
            }}
            onChange={(e) => setContentMessage(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    if (contentMessage.trim() !== "") {
                      handleSendMessage(contentMessage);
                      setContentMessage("");
                    }
                  }}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </InputArea>
    </MainContainer>
  );
}
