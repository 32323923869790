import React, { useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default, // Substituído pelo background do tema
    minHeight: "100vh",
  },
  mainPaper: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper, // Cor de fundo do paper seguindo o tema
    maxWidth: 1200,
    margin: "0 auto",
  },
  sectionTitle: {
    color: theme.palette.text.primary, // Cor de texto principal do tema
    marginBottom: theme.spacing(2),
  },
  sectionSubtitle: {
    color: theme.palette.primary.main, // Cor primária do tema
    fontWeight: 600,
    marginTop: theme.spacing(3),
  },
  formContainer: {
    marginTop: theme.spacing(2),
  },
  endpointDetails: {
    backgroundColor: theme.palette.action.hover, // Use um tom mais leve da paleta
    padding: theme.spacing(2),
    borderRadius: 8,
    marginBottom: theme.spacing(3),
    fontSize: "0.9rem",
  },
  codeBlock: {
    backgroundColor: theme.palette.chatlist, // Cor para blocos de código usando a paleta de cinza
    padding: theme.spacing(1),
    borderRadius: 5,
    fontFamily: "monospace",
    fontSize: "0.875rem",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  swaggerHeader: {
    fontWeight: "bold",
    color: theme.palette.primary.main, // Cor primária do tema
  },
  swaggerSection: {
    backgroundColor: theme.palette.background.paper, // Cor de fundo da seção, seguindo o tema
    border: `1px solid ${theme.palette.divider}`, // Use a cor de borda padrão do tema
    borderRadius: 8,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    height: "100%", // Garante altura total
  },
  swaggerForm: {
    marginTop: theme.spacing(3),
  },
  swaggerDivider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  list: {
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  listItem: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary, // Cor de texto secundária do tema
  },
  listSubItem: {
    paddingLeft: theme.spacing(3),
    listStyleType: "circle",
  },
  fileInput: {
    display: "block",
  },
  smallInput: {
    "& .MuiInputBase-root": {
      fontSize: "0.875rem",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none", // Remover borda
      },
    },
  },
  equalHeightBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%", // Garante a mesma altura para as boxes
  },
}));

const MessagesAPI = () => {
  const classes = useStyles();

  const [formMessageTextData] = useState({ token: "", number: "", body: "" });
  const [formMessageMediaData] = useState({ token: "", number: "", medias: "" });
  const [file, setFile] = useState({});

  const getEndpoint = () => {
    return process.env.REACT_APP_BACKEND_URL + "/api/messages/send";
  };

  const handleSendTextMessage = async (values) => {
    const { number, body } = values;
    const data = { number, body };
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/messages/send`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${values.token}`,
        },
      });
      toast.success("Mensagem enviada com sucesso");
    } catch (error) {
      toastError(error);
    }
  };

  const handleSendMediaMessage = async (values) => {
    try {
      const firstFile = file[0];
      const data = new FormData();
      data.append("number", values.number);
      data.append("body", firstFile.name);
      data.append("medias", firstFile);

      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/messages/send`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${values.token}`,
        },
      });
      toast.success("Mensagem enviada com sucesso");
    } catch (error) {
      toastError(error);
    }
  };

  const renderTextMessageForm = () => (
    <Formik
      initialValues={formMessageTextData}
      onSubmit={async (values, actions) => {
        await handleSendTextMessage(values);
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ isSubmitting }) => (
        <Form className={classes.swaggerForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Token"
                name="token"
                variant="outlined"
                fullWidth
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Número"
                name="number"
                variant="outlined"
                fullWidth
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Mensagem"
                name="body"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12} className={classes.buttonWrapper}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Enviar"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  const renderMediaMessageForm = () => (
    <Formik
      initialValues={formMessageMediaData}
      onSubmit={async (values, actions) => {
        await handleSendMediaMessage(values);
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Token"
                name="token"
                variant="outlined"
                fullWidth
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Número"
                name="number"
                variant="outlined"
                fullWidth
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                name="medias"
                required
                onChange={(e) => setFile(e.target.files)}
                className={classes.fileInput}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Enviar"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.mainContainer}>
      <Paper className={classes.mainPaper} elevation={3}>
        <div className={classes.swaggerSection}>
          <Typography variant="h4" gutterBottom>
            Documentação para envio de mensagens
          </Typography>

          <Typography variant="h6" className={classes.sectionSubtitle}>
            Métodos de Envio
          </Typography>
          <Typography variant="body1">
            Abaixo estão os dois métodos principais para o envio de mensagens:
          </Typography>
          <ul className={classes.list}>
            <li className={classes.listItem}>Mensagens de Texto</li>
            <li className={classes.listItem}>Mensagens de Mídia</li>
          </ul>

          <Typography variant="h6" className={classes.sectionSubtitle}>
            Observações importantes
          </Typography>
          <Typography variant="body1">
            <ul className={classes.list}>
              <li className={classes.listItem}>
                Antes de enviar mensagens, é necessário o cadastro do token vinculado à conexão que enviará as mensagens.
              </li>
              <li className={classes.listItem}>
                O número para envio deve estar no formato internacional:
                <ul className={classes.listSubItem}>
                  <li className={classes.listItem}>Código do país</li>
                  <li className={classes.listItem}>DDD</li>
                  <li className={classes.listItem}>Número</li>
                </ul>
              </li>
            </ul>
          </Typography>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className={`${classes.swaggerSection} ${classes.equalHeightBox}`}>
              <Typography variant="h6" className={classes.swaggerHeader}>
                1. Mensagens de Texto
              </Typography>
              <Typography>
                <b>Endpoint:</b> {getEndpoint()} <br />
                <b>Método:</b> POST <br />
                <b>Headers:</b> Content-Type: application/json, <br></br>Authorization: Bearer [token]
              </Typography>
              <div className={classes.codeBlock}>
                {`{ "number": "558599999999", "body": "Sua mensagem" }`}
              </div>
              {renderTextMessageForm()}
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className={`${classes.swaggerSection} ${classes.equalHeightBox}`}>
              <Typography variant="h6" className={classes.swaggerHeader}>
                2. Mensagens de Mídia
              </Typography>
              <Typography>
                <b>Endpoint:</b> {getEndpoint()} <br />
                <b>Método:</b> POST <br />
                <b>Headers:</b> Content-Type: multipart/form-data, <br></br>Authorization: Bearer [token]
              </Typography>
              <div className={classes.codeBlock}>
                {`FormData: { "number": "558599999999", "medias": arquivo }`}
              </div>
              {renderMediaMessageForm()}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default MessagesAPI;
