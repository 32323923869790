import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import useSettings from "../../hooks/useSettings";
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "#f5f5f5",
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        textAlign: 'center', // Centraliza o conteúdo do container
    },
    paper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.options, // Usando a cor de fundo do papel no tema
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
        transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
        '&:hover': {
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.24), 0 4px 8px rgba(0, 0, 0, 0.22)"
        },
        minHeight: 280,
    },
    selectContainer: {
        width: "100%",
        textAlign: "left",
        alignSelf: "flex-start"
    },
    title: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
        fontSize: '1.2rem',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
    }
}));

export default function Options(props) {
    const { settings, scheduleTypeChanged } = props;
    const classes = useStyles();
    const [userRating, setUserRating] = useState("disabled");
    const [scheduleType, setScheduleType] = useState("disabled");
    const [callType, setCallType] = useState("enabled");
    const [chatbotType, setChatbotType] = useState("");
    const [CheckMsgIsGroup, setCheckMsgIsGroupType] = useState("enabled");

    const [loadingIpIxcType, setLoadingIpIxcType] = useState(false);
    const [loadingTokenIxcType, setLoadingTokenIxcType] = useState(false);
    const [loadingIpMkauthType, setLoadingIpMkauthType] = useState(false);
    const [loadingClientIdMkauthType, setLoadingClientIdMkauthType] = useState(false);
    const [loadingClientSecrectMkauthType, setLoadingClientSecrectMkauthType] = useState(false);
    const [loadingAsaasType, setLoadingAsaasType] = useState(false);
    const [loadingSgaType, setLoadingSgaType] = useState(false);

    const [ipixcType, setIpIxcType] = useState("");
    const [tokenixcType, setTokenIxcType] = useState("");
    const [ipmkauthType, setIpMkauthType] = useState("");
    const [clientidmkauthType, setClientIdMkauthType] = useState("");
    const [clientsecretmkauthType, setClientSecrectMkauthType] = useState("");
    const [asaasType, setAsaasType] = useState("");
    const [sgaType, setSgaType] = useState("");

    //INICIO SGP INTEGRACAO
    const [ipsgpType, setIpSGPType] = useState("");
    const [loadingIpSGPType, setLoadingIpSGPType] = useState(false);
    const [tokensgpType, setTokenSGPType] = useState("");
    const [appsgpType, setAppSGPType] = useState("");
    const [loadingAppSGPType, setLoadingAppSGPType] = useState(false);
    const [loadingTokenSGPType, setLoadingTokenSGPType] = useState(false);

    const { update } = useSettings();

    useEffect(() => {
        if (Array.isArray(settings) && settings.length) {
            const ipixcType = settings.find((s) => s.key === "ipixc");
            if (ipixcType) {
                setIpIxcType(ipixcType.value);
            }

            const tokenixcType = settings.find((s) => s.key === "tokenixc");
            if (tokenixcType) {
                setTokenIxcType(tokenixcType.value);
            }

            const ipmkauthType = settings.find((s) => s.key === "ipmkauth");
            if (ipmkauthType) {
                setIpMkauthType(ipmkauthType.value);
            }

            const clientidmkauthType = settings.find((s) => s.key === "clientidmkauth");
            if (clientidmkauthType) {
                setClientIdMkauthType(clientidmkauthType.value);
            }

            const clientsecretmkauthType = settings.find((s) => s.key === "clientsecretmkauth");
            if (clientsecretmkauthType) {
                setClientSecrectMkauthType(clientsecretmkauthType.value);
            }

            const asaasType = settings.find((s) => s.key === "asaas");
            if (asaasType) {
                setAsaasType(asaasType.value);
            }

            const sgaType = settings.find((s) => s.key === "sga");
            if (sgaType) {
                setSgaType(sgaType?.value);
            }

            const ipsgpType = settings.find((s) => s.key === "ipsgp");
            if (ipsgpType) {
                setIpSGPType(ipsgpType.value);
            }

            const tokensgpType = settings.find((s) => s.key === "tokensgp");
            if (tokensgpType) {
                setTokenSGPType(tokensgpType.value);
            }
            const appsgpType = settings.find((s) => s.key === "appsgp");
            if (appsgpType) {
                setAppSGPType(appsgpType.value);
            }

        }
    }, [settings]);


    async function handleChangeIPSGP(value) {
        setIpSGPType(value);
        setLoadingIpSGPType(true);
        await update({
            key: "ipsgp",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingIpSGPType(false);
    }

    async function handleChangeTokenSGP(value) {
        setTokenSGPType(value);
        setLoadingTokenSGPType(true);
        await update({
            key: "tokensgp",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingTokenSGPType(false);
    }
    async function handleChangeAppSGP(value) {
        setAppSGPType(value);
        setLoadingAppSGPType(true);
        await update({
            key: "appsgp",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingAppSGPType(false);
    }


    async function handleChangeIPIxc(value) {
        setIpIxcType(value);
        setLoadingIpIxcType(true);
        await update({
            key: "ipixc",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingIpIxcType(false);
    }

    async function handleChangeTokenIxc(value) {
        setTokenIxcType(value);
        setLoadingTokenIxcType(true);
        await update({
            key: "tokenixc",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingTokenIxcType(false);
    }

    async function handleChangeIpMkauth(value) {
        setIpMkauthType(value);
        setLoadingIpMkauthType(true);
        await update({
            key: "ipmkauth",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingIpMkauthType(false);
    }

    async function handleChangeClientIdMkauth(value) {
        setClientIdMkauthType(value);
        setLoadingClientIdMkauthType(true);
        await update({
            key: "clientidmkauth",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingClientIdMkauthType(false);
    }

    async function handleChangeClientSecrectMkauth(value) {
        setClientSecrectMkauthType(value);
        setLoadingClientSecrectMkauthType(true);
        await update({
            key: "clientsecretmkauth",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingClientSecrectMkauthType(false);
    }

    async function handleChangeAsaas(value) {
        setAsaasType(value);
        setLoadingAsaasType(true);
        await update({
            key: "asaas",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingAsaasType(false);
    }

    async function handleChangeSga(value) {
        setSgaType(value);
        setLoadingSgaType(true);
        await update({
            key: "sga",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingSgaType(false);
    }

    return (
        <div className={classes.container}>
            <Typography variant="h6" className={classes.sectionTitle}>
                INTEGRAÇÕES
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <Paper className={classes.paper}>

                        <Typography variant="h6" className={classes.sectionTitle}>
                            MK-AUTH
                        </Typography>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="ipmkauth"
                                name="ipmkauth"
                                margin="dense"
                                label="Ip Mk-Auth"
                                variant="outlined"
                                value={ipmkauthType}
                                onChange={async (e) => {
                                    handleChangeIpMkauth(e.target.value);
                                }}
                            />
                            <FormHelperText>
                                {loadingIpMkauthType && "Atualizando..."}
                            </FormHelperText>
                        </FormControl>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="clientidmkauth"
                                name="clientidmkauth"
                                margin="dense"
                                label="Client Id"
                                variant="outlined"
                                value={clientidmkauthType}
                                onChange={async (e) => {
                                    handleChangeClientIdMkauth(e.target.value);
                                }}
                            />
                            <FormHelperText>
                                {loadingClientIdMkauthType && "Atualizando..."}
                            </FormHelperText>
                        </FormControl>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="clientsecretmkauth"
                                name="clientsecretmkauth"
                                margin="dense"
                                label="Client Secret"
                                variant="outlined"
                                value={clientsecretmkauthType}
                                onChange={async (e) => {
                                    handleChangeClientSecrectMkauth(e.target.value);
                                }}
                            />
                            <FormHelperText>
                                {loadingClientSecrectMkauthType && "Atualizando..."}
                            </FormHelperText>
                        </FormControl>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            IXC
                        </Typography>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="ipixc"
                                name="ipixc"
                                margin="dense"
                                label="IP do IXC"
                                variant="outlined"
                                value={ipixcType}
                                onChange={async (e) => {
                                    handleChangeIPIxc(e.target.value);
                                }}
                            />
                            <FormHelperText>
                                {loadingIpIxcType && "Atualizando..."}
                            </FormHelperText>
                        </FormControl>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="tokenixc"
                                name="tokenixc"
                                margin="dense"
                                label="Token do IXC"
                                variant="outlined"
                                value={tokenixcType}
                                onChange={async (e) => {
                                    handleChangeTokenIxc(e.target.value);
                                }}
                            />
                            <FormHelperText>
                                {loadingTokenIxcType && "Atualizando..."}
                            </FormHelperText>
                        </FormControl>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            ASAAS
                        </Typography>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="asaas"
                                name="asaas"
                                margin="dense"
                                label="Token Asaas"
                                variant="outlined"
                                value={asaasType}
                                onChange={async (e) => {
                                    handleChangeAsaas(e.target.value);
                                }}
                            />
                            <FormHelperText>
                                {loadingAsaasType && "Atualizando..."}
                            </FormHelperText>
                        </FormControl>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            SGA
                        </Typography>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="sga"
                                name="sga"
                                margin="dense"
                                label="Token SGA"
                                variant="outlined"
                                value={sgaType}
                                onChange={async (e) => {
                                    handleChangeSga(e.target.value);
                                }}
                            />
                            <FormHelperText>
                                {loadingSgaType && "Atualizando..."}
                            </FormHelperText>
                        </FormControl>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            SGP
                        </Typography>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="ipsgp"
                                name="ipsgp"
                                margin="dense"
                                label="Url do SGP"
                                variant="outlined"
                                value={ipsgpType}
                                onChange={async (e) => {
                                    handleChangeIPSGP(e.target.value);
                                }}
                            >
                            </TextField>
                            <TextField
                                id="tokensgp"
                                name="tokensgp"
                                margin="dense"
                                label="Token do SGP"
                                variant="outlined"
                                value={tokensgpType}
                                onChange={async (e) => {
                                    handleChangeTokenSGP(e.target.value);
                                }}
                            >
                            </TextField>
                            <TextField
                                id="application"
                                name="application"
                                margin="dense"
                                label="Aplicação"
                                variant="outlined"
                                value={appsgpType}
                                onChange={async (e) => {
                                    handleChangeAppSGP(e.target.value);
                                }}
                            >
                            </TextField>
                            <FormHelperText>
                                {loadingSgaType && "Atualizando..."}
                            </FormHelperText>
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
