import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    DialogActions,
    CircularProgress,
    TextField,
    Switch,
    FormControlLabel,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Tabs,
    Tab,
    Typography,
    Paper,
} from "@material-ui/core";
import { Autorenew, FileCopy } from "@material-ui/icons";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import usePlans from "../../hooks/usePlans/index.js";
import useCompanies from "../../hooks/useCompanies";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    tabPanel: {
        padding: theme.spacing(2),
    },
    // Adicione um estilo para a box vermelha
    infoBox: {
        backgroundColor: "#2c7be573", // Definindo a cor de fundo vermelha
        padding: theme.spacing(2), // Adicionando um espaçamento interno
        marginBottom: theme.spacing(2), // Adicionando margem inferior para separar do conteúdo abaixo
    },
    tab: {
        backgroundColor: theme.palette.options,
        borderRadius: 4,
        '&.Mui-selected': {
            color: `${theme.palette.primary.main} !important`,  // Forçar o uso da cor vermelha
            borderBottom: `3px solid ${theme.palette.primary.main} !important`,  // Forçar borda inferior
            fontWeight: 'bold !important',  // Deixar o texto em negrito
        },
    },

    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative', // Posicionamento relativo para controlar os ícones absolutamente
        padding: theme.spacing(1),
        minHeight: "38px"
    },
    icon: {
        position: 'absolute', // Posiciona o ícone de forma absoluta para controle preciso
        left: '50%', // Move o ícone para o centro do botão
        top: '50%', // Alinha o ícone ao meio verticalmente
        transform: 'translate(-50%, -50%)', // Ajusta precisamente o ícone para o centro
    },
}));

const SessionSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
});

function TabPanel({ children, value, index }) {
    return value === index && <Box p={3}>{children}</Box>;
}

const WhatsAppOficialModal = ({ open, onClose, whatsAppId }) => {
    const classes = useStyles();
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const initialState = {
        name: "",
        greetingMessage: "",
        complationMessage: "",
        outOfHoursMessage: "",
        ratingMessage: "",
        isDefault: false,
        token: "",
        status: "CONNECTED",
        provider: "beta",
        channel: "cloudapi",
        timeSendQueue: 0,
        sendIdQueue: 0,
        expiresInactiveMessage: "",
        expiresTicket: 0,
        timeUseBotQueues: 0,
        maxUseBotQueues: 3,
        flowIdWelcome: 0,
        enablePowerCrm: false,
        enableRemarketing: false,
        identifyNumberCloudApi: "",
        identifyAccCloudApi: "",
        TokenCloudApi: "",
    };
    const [whatsApp, setWhatsApp] = useState(initialState);
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
    const [queues, setQueues] = useState([]);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [prompts, setPrompts] = useState([]);
    const [flows, setFlows] = useState([]);
    const [flowIdWelcome, setFlowIdWelcome] = useState();
    const [webhooks, setWebhooks] = useState([]);
    const [autoToken, setAutoToken] = useState("");
    const [copied, setCopied] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [powerCRMEnabled, setPowerCRMEnabled] = useState(false);
    const [remarketingEnabled, setRemarketingEnabled] = useState(false);
    const { finder, getPlanCompany } = usePlans();
    const { find, updateSchedules } = useCompanies();

    useEffect(() => {
        if (open) {
            setTabValue(0);
        }
        (async () => {
            try {
                const companyId = localStorage.getItem("companyId");
                const company = await find(companyId);
                const powerCRM = await finder(company.planId)
                setPowerCRMEnabled(powerCRM.usePowerCrm)
                setRemarketingEnabled(powerCRM.useRemarketing)
                const { data } = await api.get("/prompt");
                const response = await api.get('/flowbuilder');
                if (Array.isArray(response.data?.flows) && response.data.flows.length > 0) {
                    setFlows(response.data.flows)
                }
                setPrompts(data.prompts);
            } catch (err) {
                toastError(err);
            }
        })();
    }, [whatsAppId]);

    useEffect(() => {
        const fetchSession = async () => {
            if (!whatsAppId) return;

            try {
                const { data } = await api.get(`whatsapp/${whatsAppId}?session=0`);
                if (data && data?.flowIdWelcome) {
                    const { data: flowDefault } = await api.get(`flowbuilder/${data.flowIdWelcome}`);
                    const selectedFlowIdWelcome = flowDefault?.flow.id;
                    setFlowIdWelcome(selectedFlowIdWelcome);
                }
                setWhatsApp(data);
                data.promptId ? setSelectedPrompt(data.promptId) : setSelectedPrompt(null);
                data.flowId ? setSelectedFlow(data.flowId) : setSelectedFlow(null);

                const whatsQueueIds = data.queues?.map((queue) => queue.id);
                setSelectedQueueIds(whatsQueueIds);
                setAutoToken(data.token);
            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();
    }, [whatsAppId]);

    useEffect(() => {
        if (!whatsAppId && !whatsApp.token) {
            setAutoToken(generateRandomCode(30));
        } else if (whatsAppId && !whatsApp.token) {
            setAutoToken(generateRandomCode(30));
        } else {
            setAutoToken(whatsApp.token);
        }
    }, [whatsAppId, whatsApp.token]);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/queue");
                setQueues(data);
            } catch (err) {
                toastError(err);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/flowbuilder");
                setWebhooks(data.flows);
            } catch (err) {
                toastError(err);
            }
        })();
    }, []);

    function generateRandomCode(length) {
        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyvz0123456789";
        let code = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            code += charset.charAt(randomIndex);
        }
        return code;
    }

    const handleRefreshToken = () => {
        setAutoToken(generateRandomCode(30));
    };

    const handleCopyToken = () => {
        navigator.clipboard.writeText(autoToken);
        setCopied(true);
    };

    const handleSaveWhatsApp = async (values) => {
        if (!whatsAppId) setAutoToken(generateRandomCode(30));

        const whatsappData = {
            ...values,
            queueIds: selectedQueueIds,
            promptId: selectedPrompt ? selectedPrompt : null,
            flowIdWelcome: flowIdWelcome,
            token: autoToken ? autoToken : null,
        };
        delete whatsappData["queues"];
        delete whatsappData["session"];

        try {
            if (whatsAppId) {
                await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
            } else {
                await api.post("/whatsapp", whatsappData);
            }
            toast.success(i18n.t("whatsappModal.success"));
            handleClose();
        } catch (err) {
            toastError(err);
        }
    };


    const handleChangeQueue = (e) => {
        setSelectedQueueIds(e);
        setSelectedPrompt(null);
        setFlowIdWelcome(null);
    };

    const handleChangePrompt = (e) => {
        setSelectedPrompt(e.target.value);
        setSelectedQueueIds([]);
        setFlowIdWelcome(null);
    };

    const handleChangeFlowIdWelcome = (e, setFieldValue) => {
        setFlowIdWelcome(e.target.value);  // Exemplo: atualizar o flowIdWelcome (se necessário)
        setSelectedQueueIds([]);  // Exemplo: resetar outros estados (se necessário)
        setSelectedPrompt(null);  // Exemplo: resetar o prompt (se necessário)
        setFieldValue("greetingMessage", "");
    };

    const handleClose = () => {
        onClose();
        setWhatsApp(initialState);
        setCopied(false);
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={false} // Remove o comportamento de largura automática
                PaperProps={{
                    style: {
                        width: '1000px',  // Largura fixa
                        height: '610px', // Altura fixa
                        margin: 'auto',  // Centraliza horizontalmente e verticalmente
                        display: 'flex',
                        flexDirection: 'column',  // Para alinhar o conteúdo corretamente
                    },
                }}
            >
                <Formik
                    initialValues={whatsApp}
                    enableReinitialize={true}
                    validationSchema={SessionSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveWhatsApp(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, touched, errors, isSubmitting, setFieldValue }) => (
                        <Form>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px 24px' }}>
                                <DialogTitle style={{ margin: 0, padding: 0 }}>
                                    {whatsAppId
                                        ? i18n.t("Editar WhatsApp Oficial")
                                        : i18n.t("Adicionar WhatsApp Oficial")}
                                </DialogTitle>

                                {/* Botões ao lado direito do título */}
                                <div>
                                    <Button
                                        onClick={handleClose}
                                        color="secondary"
                                        variant="outlined"
                                        style={{ marginRight: '8px' }}
                                    >
                                        {i18n.t("whatsappModal.buttons.cancel")}
                                    </Button>
                                    <Button
                                        type="submit"  // O botão agora está associado ao Formik submit
                                        color="primary"
                                        variant="contained"
                                        disabled={isSubmitting}
                                    >
                                        {whatsAppId
                                            ? i18n.t("whatsappModal.buttons.okEdit")
                                            : i18n.t("whatsappModal.buttons.okAdd")}
                                    </Button>
                                </div>
                            </div>
                            <Box borderBottom={1} borderColor="divider">
                                <Tabs
                                    value={tabValue}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    scrollButtons="on"
                                    variant="fullWidth"
                                    onChange={handleTabChange}
                                    classes={{ root: classes.tabsRoot }}
                                >
                                    <Tab label="Configurações Gerais" classes={{ selected: classes.selectedTab, root: classes.tab }} />
                                    <Tab label="Mensagens" classes={{ selected: classes.selectedTab, root: classes.tab }} />
                                    <Tab label="Integrações" classes={{ selected: classes.selectedTab, root: classes.tab }} />
                                    <Tab label="Redirecionamento" classes={{ selected: classes.selectedTab, root: classes.tab }} />
                                </Tabs>
                            </Box>
                            <DialogContent
                                style={{ flex: 1, overflowY: 'auto' }}  // Remova o 'dividers' para eliminar a linha
                            >
                                <TabPanel value={tabValue} index={0}>
                                    {/* Configurações Gerais e Token na mesma linha */}
                                    <Grid container spacing={2}>
                                        {/* Configurações Gerais */}
                                        <Grid item xs={6}>
                                            <Box border={1} borderColor="grey.300" borderRadius={4} p={2} height="100%">
                                                <h3>{i18n.t("whatsappModal.form.name")}</h3>
                                                <div className={classes.multFieldLine}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Field
                                                                as={TextField}
                                                                autoFocus
                                                                name="name"
                                                                error={touched.name && Boolean(errors.name)}
                                                                helperText={touched.name && errors.name}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            {powerCRMEnabled === true ? (
                                                                <Grid item xs={6}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Field
                                                                                as={Switch}
                                                                                color="primary"
                                                                                name="enablePowerCrm"
                                                                                checked={values.enablePowerCrm}
                                                                            />
                                                                        }
                                                                        label={i18n.t("Ativar Power CRM ?")}
                                                                    />
                                                                </Grid>
                                                            ) : null}
                                                            {remarketingEnabled === true ? (
                                                                <Grid item xs={6}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Field
                                                                                as={Switch}
                                                                                color="primary"
                                                                                name="enableRemarketing"
                                                                                checked={values.enableRemarketing}
                                                                            />
                                                                        }
                                                                        label={i18n.t("Ativar Remarketing ?")}
                                                                    />
                                                                </Grid>
                                                            ) : null}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Box>
                                        </Grid>

                                        {/* Token */}
                                        <Grid item xs={6}>
                                            <Box border={1} borderColor="grey.300" borderRadius={4} p={2} height="100%">
                                                <h3>Token</h3>
                                                <Grid container alignItems="center" spacing={1}>
                                                    <Grid item xs>
                                                        <Field
                                                            as={TextField}
                                                            label=""
                                                            type="text"
                                                            fullWidth
                                                            value={autoToken}
                                                            variant="outlined"
                                                            margin="dense"
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            onClick={() => {
                                                                handleRefreshToken();
                                                                toast.success("Token atualizado com sucesso!");
                                                            }}
                                                            disabled={isSubmitting}
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.button}
                                                            startIcon={<Autorenew className={classes.icon} />}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            onClick={() => {
                                                                handleCopyToken();
                                                                toast.success("Token copiado com sucesso!");
                                                            }}
                                                            variant="contained"
                                                            color="secondary"
                                                            className={classes.button}
                                                            startIcon={<FileCopy className={classes.icon} />}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        {/* Identificação do número de telefone */}
                                        <Grid item xs={6}>
                                            <Box border={1} borderColor="grey.300" borderRadius={4} p={2} height="100%">
                                                <h3>{i18n.t("Identificação do número de telefone")}</h3>
                                                <div className={classes.multFieldLine}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Field
                                                                as={TextField}
                                                                autoFocus
                                                                name="identifyNumberCloudApi"
                                                                error={touched.identifyNumberCloudApi && Boolean(errors.identifyNumberCloudApi)}
                                                                helperText={touched.identifyNumberCloudApi && errors.identifyNumberCloudApi}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Box>
                                        </Grid>
                                        {/* Identificação da conta do WhatsApp Business */}
                                        <Grid item xs={6}>
                                            <Box border={1} borderColor="grey.300" borderRadius={4} p={2} height="100%">
                                                <h3>{i18n.t("Identificação da conta do WhatsApp Business")}</h3>
                                                <div className={classes.multFieldLine}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Field
                                                                as={TextField}
                                                                autoFocus
                                                                name="identifyAccCloudApi"
                                                                error={touched.identifyAccCloudApi && Boolean(errors.identifyAccCloudApi)}
                                                                helperText={touched.identifyAccCloudApi && errors.identifyAccCloudApi}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Box>
                                        </Grid>
                                        {/* Token de acesso  PERMANENTE */}
                                        <Grid item xs={12}>
                                            <Box border={1} borderColor="grey.300" borderRadius={4} p={2} height="100%">
                                                <h3>{i18n.t("Token de acesso  PERMANENTE")}</h3>
                                                <div className={classes.multFieldLine}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Field
                                                                as={TextField}
                                                                autoFocus
                                                                name="TokenCloudApi"
                                                                error={touched.TokenCloudApi && Boolean(errors.TokenCloudApi)}
                                                                helperText={touched.TokenCloudApi && errors.TokenCloudApi}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value={tabValue} index={1}>
                                    {/* Mensagens */}
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{ height: '100%' }} // Garante que o Grid tenha o tamanho do modal
                                    >
                                        <Grid item xs={6}>
                                            <Box border={1} borderColor="grey.300" borderRadius={4} p={2}>
                                                <h3>{i18n.t("queueModal.form.greetingMessage")}</h3>
                                                <Field
                                                    as={TextField}
                                                    type="text"
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    name="greetingMessage"
                                                    value={values.greetingMessage}  // Controlado pelo Formik
                                                    //onChange={handleChange}  // Manuseio direto do Formik
                                                    error={touched.greetingMessage && Boolean(errors.greetingMessage)}
                                                    helperText={touched.greetingMessage && errors.greetingMessage}
                                                    variant="outlined"
                                                    margin="dense"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box border={1} borderColor="grey.300" borderRadius={4} p={2}>
                                                <h3>{i18n.t("queueModal.form.complationMessage")}</h3>
                                                <Field
                                                    as={TextField}
                                                    type="complationMessage"
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    name="complationMessage"
                                                    error={touched.complationMessage && Boolean(errors.complationMessage)}
                                                    helperText={touched.complationMessage && errors.complationMessage}
                                                    variant="outlined"
                                                    margin="dense"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box border={1} borderColor="grey.300" borderRadius={4} p={2}>
                                                <h3>{i18n.t("queueModal.form.outOfHoursMessage")}</h3>
                                                <Field
                                                    as={TextField}
                                                    type="outOfHoursMessage"
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    name="outOfHoursMessage"
                                                    error={touched.outOfHoursMessage && Boolean(errors.outOfHoursMessage)}
                                                    helperText={touched.outOfHoursMessage && errors.outOfHoursMessage}
                                                    variant="outlined"
                                                    margin="dense"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box border={1} borderColor="grey.300" borderRadius={4} p={2}>
                                                <h3>{i18n.t("queueModal.form.ratingMessage")}</h3>
                                                <Field
                                                    as={TextField}
                                                    type="ratingMessage"
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    name="ratingMessage"
                                                    error={touched.ratingMessage && Boolean(errors.ratingMessage)}
                                                    helperText={touched.ratingMessage && errors.ratingMessage}
                                                    variant="outlined"
                                                    margin="dense"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value={tabValue} index={2}>
                                    {/* Integrações */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Box border={1} borderColor="grey.300" borderRadius={4} p={2} mb={2}>
                                                <h3>Fluxo do Flowbuilder</h3>
                                                <FormControl fullWidth margin="dense" variant="outlined">
                                                    <InputLabel id="flowIdWelcome-selection-label">
                                                        Selecione o Fluxo
                                                    </InputLabel>
                                                    <Select
                                                        name="flowIdWelcome"
                                                        value={flowIdWelcome || ""}
                                                        onChange={(e) => handleChangeFlowIdWelcome(e, setFieldValue)}
                                                        id="flowIdWelcome"
                                                        label="Selecione o Fluxo"
                                                        labelId="flowIdWelcome-selection-label"
                                                    >
                                                        <MenuItem value={null}>{"Desabilitado"}</MenuItem>
                                                        {webhooks.map((webhook) => (
                                                            <MenuItem key={webhook.id} value={webhook.id}>
                                                                {webhook.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box border={1} borderColor="grey.300" borderRadius={4} p={2} mb={2}>
                                                <h3>Integração ChatGPT</h3>
                                                <FormControl fullWidth margin="dense" variant="outlined">
                                                    <InputLabel id="dialog-select-prompt-label">
                                                        {i18n.t("whatsappModal.form.prompt")}
                                                    </InputLabel>
                                                    <Select
                                                        name="promptId"
                                                        value={selectedPrompt || ""}
                                                        onChange={handleChangePrompt}
                                                        id="dialog-select-prompt"
                                                        label={i18n.t("whatsappModal.form.prompt")}
                                                        fullWidth
                                                    >
                                                        {prompts.map((prompt, index) => (
                                                            <MenuItem
                                                                key={prompt.id || index}
                                                                value={prompt.id || null}
                                                            >
                                                                {prompt.name || "Nenhum"}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box border={1} borderColor="grey.300" borderRadius={4} p={2} style={{ height: '100%' }}>
                                                <h3>Filas</h3>
                                                <QueueSelect
                                                    selectedQueueIds={selectedQueueIds}
                                                    onChange={(selectedIds) => handleChangeQueue(selectedIds)}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value={tabValue} index={3}>
                                    {/* Redirecionamento */}
                                    <Box border={1} borderColor="grey.300" borderRadius={4} p={2} style={{ height: "100%" }}>
                                        <h3>{i18n.t("whatsappModal.form.queueRedirection")}</h3>
                                        <p>{i18n.t("whatsappModal.form.queueRedirectionDesc")}</p>
                                        <Grid spacing={2} container>
                                            <Grid xs={6} md={6} item>
                                                <FormControl variant="outlined" margin="dense" className={classes.FormControl} fullWidth>
                                                    <InputLabel id="sendIdQueue-selection-label">
                                                        {i18n.t("whatsappModal.form.sendIdQueue")}
                                                    </InputLabel>
                                                    <Field
                                                        as={Select}
                                                        name="sendIdQueue"
                                                        id="sendIdQueue"
                                                        label={i18n.t("whatsappModal.form.sendIdQueue")}
                                                        placeholder={i18n.t("whatsappModal.form.sendIdQueue")}
                                                        labelId="sendIdQueue-selection-label"
                                                    >
                                                        <MenuItem value={0}>&nbsp;</MenuItem>
                                                        {queues.map((queue) => (
                                                            <MenuItem key={queue.id} value={queue.id}>
                                                                {queue.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={6} md={6} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("whatsappModal.form.timeSendQueue")}
                                                    fullWidth
                                                    name="timeSendQueue"
                                                    variant="outlined"
                                                    margin="dense"
                                                    error={touched.timeSendQueue && Boolean(errors.timeSendQueue)}
                                                    helperText={touched.timeSendQueue && errors.timeSendQueue}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid spacing={2} container>
                                            {/* QUANTIDADE MÁXIMA DE VEZES QUE O CHATBOT VAI SER ENVIADO */}
                                            <Grid xs={12} md={6} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("whatsappModal.form.maxUseBotQueues")}
                                                    fullWidth
                                                    name="maxUseBotQueues"
                                                    variant="outlined"
                                                    margin="dense"
                                                    error={touched.maxUseBotQueues && Boolean(errors.maxUseBotQueues)}
                                                    helperText={touched.maxUseBotQueues && errors.maxUseBotQueues}
                                                />
                                            </Grid>
                                            {/* TEMPO PARA ENVIO DO CHATBOT */}
                                            <Grid xs={12} md={6} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("whatsappModal.form.timeUseBotQueues")}
                                                    fullWidth
                                                    name="timeUseBotQueues"
                                                    variant="outlined"
                                                    margin="dense"
                                                    error={touched.timeUseBotQueues && Boolean(errors.timeUseBotQueues)}
                                                    helperText={touched.timeUseBotQueues && errors.timeUseBotQueues}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid spacing={2} container>
                                            {/* ENCERRAR CHATS ABERTOS APÓS X HORAS */}
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("whatsappModal.form.expiresTicket")}
                                                    fullWidth
                                                    name="expiresTicket"
                                                    variant="outlined"
                                                    margin="dense"
                                                    error={touched.expiresTicket && Boolean(errors.expiresTicket)}
                                                    helperText={touched.expiresTicket && errors.expiresTicket}
                                                />
                                            </Grid>
                                        </Grid>
                                        {/* MENSAGEM POR INATIVIDADE*/}
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("whatsappModal.form.expiresInactiveMessage")}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                name="expiresInactiveMessage"
                                                error={touched.expiresInactiveMessage && Boolean(errors.expiresInactiveMessage)}
                                                helperText={touched.expiresInactiveMessage && errors.expiresInactiveMessage}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>
                                    </Box>
                                </TabPanel>
                            </DialogContent>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default React.memo(WhatsAppOficialModal);
