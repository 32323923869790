import React, { useState, useEffect, useCallback } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Grid,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Typography,
    Box,
    TextField,
    CircularProgress
} from "@material-ui/core";
import { GetApp as DownloadIcon, PictureAsPdf as PdfIcon, TableChart as CsvIcon } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { format } from "date-fns";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    buttonWrapper: {
        marginTop: theme.spacing(2),
    },
    reportTable: {
        marginTop: theme.spacing(4),
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    downloadButtons: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    customInputPadding: {
        padding: "10.5px 14px",
    },
    errorMessage: {
        color: 'red',
        marginTop: theme.spacing(1),
    }
}));

// Função para formatar a data no formato dd/mm/yyyy
const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
};

const Reports = () => {
    const classes = useStyles();
    const [reportType, setReportType] = useState("");
    const [tags, setTags] = useState([]);
    const [users, setUsers] = useState([]);
    const [whatsapps, setWhatsapps] = useState([]); // Para armazenar a lista de WhatsApps
    const [selectedTags, setSelectedTags] = useState("");
    const [selectedUsers, setSelectedUsers] = useState("");
    const [selectedWhatsapp, setSelectedWhatsapp] = useState(""); // Seleção de WhatsApp
    const [startDate, setStartDate] = useState(format(new Date(new Date().setMonth(new Date().getMonth() - 1)), 'yyyy-MM-dd'));
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDownloadButtons, setShowDownloadButtons] = useState(false);
    const [error, setError] = useState("");

    const companyId = localStorage.getItem("companyId") || 1;

    const fetchTagsUsersWhatsapps = useCallback(async () => {
        try {
            const { data: tagData } = await api.get("/tags/", { params: { companyId } });
            setTags(tagData.tags);

            const { data: userData } = await api.get("/users/", { params: { companyId } });
            setUsers(userData.users);

            const { data: whatsappData } = await api.get("/whatsapp/?session=0");
            setWhatsapps(whatsappData); // Buscando a lista de WhatsApps

            setLoading(false);
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    }, [companyId]);

    const validateDates = () => {
        // Validação das datas
        if (startDate > endDate) {
            setError("A data de fim não pode ser anterior à data de início.");
            return false;
        }
        if (reportType === "tags" && selectedTags === "") {
            setError("A Tag deve ser selecionada.");
            return false;
        }
        if (reportType === "whatsapp" && selectedWhatsapp === "") {
            setError("O WhatsApp deve ser selecionado.");
            return false;
        }
        setError("");
        return true;
    };

    const fetchReport = useCallback(async () => {
        if (!validateDates()) return;

        setLoading(true);
        setShowDownloadButtons(false);

        try {
            if (reportType === "tags") {
                const { data } = await api.post("/report/tags", { startDate, endDate, tagName: selectedTags, companyId });
                setReportData(data.tagReport);
            } else if (reportType === "users") {
                const { data } = await api.post("/report/attendance", { startDate, endDate, userId: selectedUsers, companyId });
                setReportData(data);
            } else if (reportType === "whatsapp") {
                const { data } = await api.post("/report/connection", { startDate, endDate, whatsappId: selectedWhatsapp, companyId }); // Relatório de WhatsApp
                setReportData(data.whatsappReport);
            }

            setLoading(false);
            setShowDownloadButtons(true);
        } catch (error) {
            toastError(error);
            setLoading(false);
        }
    }, [startDate, endDate, selectedTags, selectedUsers, selectedWhatsapp, reportType, companyId]);

    useEffect(() => {
        setLoading(true);
        fetchTagsUsersWhatsapps();
    }, [fetchTagsUsersWhatsapps]);

    const handleReportChange = (e) => {
        setReportType(e.target.value);
    };

    const handleTagSelect = (event) => {
        setSelectedTags(event.target.value);
    };

    const handleUserSelect = (event) => {
        setSelectedUsers(event.target.value);
    };

    const handleWhatsappSelect = (event) => {
        setSelectedWhatsapp(event.target.value);
    };

    const downloadXLSX = () => {
        let exportData = [];

        if (reportType === "tags") {
            exportData = reportData.flatMap((tag) => {
                return tag.tickets.map((ticket) => ({
                    "Tag Name": tag.tagName,
                    "Tag Color": tag.tagColor,
                    "Total Tickets": tag.totalTickets,
                    "Ticket ID": ticket.ticketId,
                    "Ticket Status": ticket.ticketStatus,
                    "Contact Name": ticket.contactName,
                    "Contact Number": ticket.contactNumber,
                    "Created At": formatDate(ticket.createdAt),
                    "Updated At": formatDate(ticket.updatedAt),
                }));
            });
        } else if (reportType === "whatsapp") {
            exportData = reportData.flatMap((whatsapp) => {
                return whatsapp.tickets.map((ticket) => ({
                    "WhatsApp Name": whatsapp.whatsappName,
                    "Total Tickets": whatsapp.totalTickets,
                    "Ticket ID": ticket.ticketId,
                    "Ticket Status": ticket.ticketStatus,
                    "Contact Name": ticket.contactName,
                    "Contact Number": ticket.contactNumber,
                    "Created At": formatDate(ticket.createdAt),
                    "Updated At": formatDate(ticket.updatedAt),
                }));
            });
        } else if (reportType === "users") {
            exportData = reportData.contacts.map((contact) => ({
                "User Name": contact.contactName,
                "Ticket ID": contact.ticketId,
                "Contact Number": contact.contactNumber,
                "Created At": formatDate(contact.createdAt),
            }));
        }

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
        XLSX.writeFile(workbook, "report.xlsx");
    };

    const downloadCSV = () => {
        let exportData = [];

        if (reportType === "tags") {
            exportData = reportData.flatMap((tag) => {
                return tag.tickets.map((ticket) => ({
                    "Tag Name": tag.tagName,
                    "Tag Color": tag.tagColor,
                    "Total Tickets": tag.totalTickets,
                    "Ticket ID": ticket.ticketId,
                    "Ticket Status": ticket.ticketStatus,
                    "Contact Name": ticket.contactName,
                    "Contact Number": ticket.contactNumber,
                    "Created At": formatDate(ticket.createdAt),
                    "Updated At": formatDate(ticket.updatedAt),
                }));
            });
        } else if (reportType === "whatsapp") {
            exportData = reportData.flatMap((whatsapp) => {
                return whatsapp.tickets.map((ticket) => ({
                    "WhatsApp Name": whatsapp.whatsappName,
                    "Total Tickets": whatsapp.totalTickets,
                    "Ticket ID": ticket.ticketId,
                    "Ticket Status": ticket.ticketStatus,
                    "Contact Name": ticket.contactName,
                    "Contact Number": ticket.contactNumber,
                    "Created At": formatDate(ticket.createdAt),
                    "Updated At": formatDate(ticket.updatedAt),
                }));
            });
        } else if (reportType === "users") {
            exportData = reportData.contacts.map((contact) => ({
                "User Name": contact.contactName,
                "Ticket ID": contact.ticketId,
                "Contact Number": contact.contactNumber,
                "Created At": formatDate(contact.createdAt),
            }));
        }

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const csv = XLSX.utils.sheet_to_csv(worksheet);
        const blob = new Blob([csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "report.csv";
        a.click();
    };

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        let tableRows = [];
        let tableColumn = [];

        if (reportType === "tags") {
            tableColumn = ["Tag Name", "Tag Color", "Total Tickets", "Ticket ID", "Status", "Contact Name", "Contact Number", "Created At", "Updated At"];
            reportData.forEach((tag) => {
                tag.tickets.forEach((ticket) => {
                    const ticketData = [
                        tag.tagName,
                        tag.tagColor,
                        tag.totalTickets,
                        ticket.ticketId,
                        ticket.ticketStatus,
                        ticket.contactName,
                        ticket.contactNumber,
                        formatDate(ticket.createdAt),
                        formatDate(ticket.updatedAt),
                    ];
                    tableRows.push(ticketData);
                });
            });
        } else if (reportType === "whatsapp") {
            tableColumn = ["WhatsApp Name", "Total Tickets", "Ticket ID", "Status", "Contact Name", "Contact Number", "Created At", "Updated At"];
            reportData.forEach((whatsapp) => {
                whatsapp.tickets.forEach((ticket) => {
                    const ticketData = [
                        whatsapp.whatsappName,
                        whatsapp.totalTickets,
                        ticket.ticketId,
                        ticket.ticketStatus,
                        ticket.contactName,
                        ticket.contactNumber,
                        formatDate(ticket.createdAt),
                        formatDate(ticket.updatedAt),
                    ];
                    tableRows.push(ticketData);
                });
            });
        } else if (reportType === "users") {
            tableColumn = ["User Name", "Ticket ID", "Contact Number", "Created At"];
            reportData.contacts.forEach((contact) => {
                const contactData = [
                    contact.contactName,
                    contact.ticketId,
                    contact.contactNumber,
                    formatDate(contact.createdAt),
                ];
                tableRows.push(contactData);
            });
        }

        doc.autoTable(tableColumn, tableRows, { startY: 20 });
        doc.text("Relatório", 14, 15);
        doc.save("report.pdf");
    };

    return (
        <MainContainer>
            <MainHeader>
                <Title>Relatórios</Title>
                <MainHeaderButtonsWrapper></MainHeaderButtonsWrapper>
            </MainHeader>

            <Grid container spacing={3}>
                {/* Tipo de Relatório */}
                <Grid item xs={12} md={3}>
                    <Box border={1} borderColor="grey.300" borderRadius={4} p={2} mb={2}>
                        <h3>Tipo de Relatório</h3>
                        <FormControl fullWidth margin="dense" variant="outlined">
                            <InputLabel id="reportType-selection-label">Escolha o tipo de relatório</InputLabel>
                            <Select
                                name="reportType"
                                value={reportType || ""}
                                onChange={handleReportChange}
                                id="reportType"
                                label="Escolha o tipo de relatório"
                                labelId="reportType-selection-label"
                            >
                                <MenuItem value="">
                                    <em>Selecione um relatório</em>
                                </MenuItem>
                                <MenuItem value="tags">Relatório de Tags</MenuItem>
                                <MenuItem value="users">Relatório de Usuários</MenuItem>
                                <MenuItem value="whatsapp">Relatório de WhatsApp</MenuItem> {/* Nova opção */}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                {/* Escolha de Tags */}
                {reportType === "tags" && (
                    <Grid item xs={12} md={3}>
                        <Box border={1} borderColor="grey.300" borderRadius={4} p={2} mb={2}>
                            <h3>Escolha as Tags</h3>
                            <FormControl fullWidth margin="dense" variant="outlined">
                                <InputLabel id="tags-selection-label">Escolha a tag</InputLabel>
                                <Select
                                    name="tags"
                                    value={selectedTags}
                                    onChange={handleTagSelect}
                                    id="tags"
                                    label="Escolha a tag"
                                    labelId="tags-selection-label"
                                    renderValue={(selected) => selected || ''}
                                >
                                    {tags.map((tag) => (
                                        <MenuItem key={tag.id} value={tag.name}>
                                            {tag.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                )}

                {/* Escolha de Usuários */}
                {reportType === "users" && (
                    <Grid item xs={12} md={3}>
                        <Box border={1} borderColor="grey.300" borderRadius={4} p={2} mb={2}>
                            <h3>Escolha o Atendente</h3>
                            <FormControl fullWidth margin="dense" variant="outlined">
                                <InputLabel id="user-selection-label">Escolha o Atendente</InputLabel>
                                <Select
                                    name="user"
                                    value={selectedUsers}
                                    onChange={handleUserSelect}
                                    id="users"
                                    label="Escolha o Atendente"
                                    labelId="users-selection-label"
                                    renderValue={(selected) => {
                                        const user = users.find((u) => u.id === selected);
                                        return user ? user.name : '';
                                    }}
                                >
                                    {users.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                )}

                {/* Escolha de WhatsApp */}
                {reportType === "whatsapp" && (
                    <Grid item xs={12} md={3}>
                        <Box border={1} borderColor="grey.300" borderRadius={4} p={2} mb={2}>
                            <h3>Escolha o WhatsApp</h3>
                            <FormControl fullWidth margin="dense" variant="outlined">
                                <InputLabel id="whatsapp-selection-label">Escolha o WhatsApp</InputLabel>
                                <Select
                                    name="whatsapp"
                                    value={selectedWhatsapp}
                                    onChange={handleWhatsappSelect}
                                    id="whatsapp"
                                    label="Escolha o WhatsApp"
                                    labelId="whatsapp-selection-label"
                                >
                                    {whatsapps.map((whatsapp) => (
                                        <MenuItem key={whatsapp.id} value={whatsapp.id}>
                                            {whatsapp.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                )}

                {/* Data de Início */}
                <Grid item xs={12} md={3}>
                    <Box border={1} borderColor="grey.300" borderRadius={4} p={2} mb={2}>
                        <h3>Data de Início</h3>
                        <FormControl fullWidth margin="dense" variant="outlined">
                            <TextField
                                id="startDate"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    classes: {
                                        input: classes.customInputPadding,
                                    },
                                }}
                            />
                        </FormControl>
                    </Box>
                </Grid>

                {/* Data de Fim */}
                <Grid item xs={12} md={3}>
                    <Box border={1} borderColor="grey.300" borderRadius={4} p={2} mb={2}>
                        <h3>Data de Fim</h3>
                        <FormControl fullWidth margin="dense" variant="outlined">
                            <TextField
                                id="endDate"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    classes: {
                                        input: classes.customInputPadding,
                                    },
                                }}
                            />
                        </FormControl>
                    </Box>
                </Grid>

                {/* Botão de Gerar Relatório */}
                <Grid item xs={12}>
                    <Box border={1} borderColor="grey.300" borderRadius={4} p={2} mb={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={fetchReport}
                            disabled={!reportType || loading}
                        >
                            {loading ? <CircularProgress size={24} /> : "Gerar Relatório"}
                        </Button>
                        {error && <div className={classes.errorMessage}>{error}</div>}
                    </Box>
                </Grid>
            </Grid>

            {/* Exibir os botões de download somente após a geração do relatório */}
            {showDownloadButtons && (
                <Box className={classes.downloadButtons}>
                    <Button
                        variant="outlined"
                        startIcon={<DownloadIcon />}
                        onClick={downloadXLSX}
                    >
                        Baixar XLSX
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<CsvIcon />}
                        onClick={downloadCSV}
                    >
                        Baixar CSV
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<PdfIcon />}
                        onClick={downloadPDF}
                    >
                        Baixar PDF
                    </Button>
                </Box>
            )}

            {/* Exibir os dados do relatório de Tags */}
            {reportType === "tags" && reportData.length > 0 && (
                <Paper className={classes.reportTable}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Resultado do Relatório
                    </Typography>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeader}>Nome da Tag</TableCell>
                                <TableCell className={classes.tableHeader}>Cor da Tag</TableCell>
                                <TableCell className={classes.tableHeader}>Total de Tickets</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((tag, index) => (
                                <React.Fragment key={index}>
                                    <TableRow>
                                        <TableCell>{tag.tagName}</TableCell>
                                        <TableCell>
                                            <div className={classes.colorCell}>
                                                <div style={{ backgroundColor: tag.tagColor }} />
                                                {tag.tagColor}
                                            </div>
                                        </TableCell>
                                        <TableCell>{tag.totalTickets}</TableCell>
                                    </TableRow>
                                    {/* Exibir os tickets relacionados com rolagem */}
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>ID do Ticket</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>Nome do Contato</TableCell>
                                                            <TableCell>Número do Contato</TableCell>
                                                            <TableCell>Data de Criação</TableCell>
                                                            <TableCell>Última Atualização</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {tag.tickets.map((ticket) => (
                                                            <TableRow key={ticket.ticketId}>
                                                                <TableCell>{ticket.ticketId}</TableCell>
                                                                <TableCell>
                                                                    {ticket.ticketStatus === "open"
                                                                        ? "Aberto"
                                                                        : ticket.ticketStatus === "closed"
                                                                            ? "Fechado"
                                                                            : "Pendente"}
                                                                </TableCell>
                                                                <TableCell>{ticket.contactName}</TableCell>
                                                                <TableCell>{ticket.contactNumber}</TableCell>
                                                                <TableCell>{new Date(ticket.createdAt).toLocaleString()}</TableCell>
                                                                <TableCell>{new Date(ticket.updatedAt).toLocaleString()}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            )}


            {/* Exibir os dados do Relatório de Usuários */}
            {reportType === "users" && reportData && reportData.contacts && (
                <Paper className={classes.reportTable}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Relatório de Usuários
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeader}>Total de Atendimentos</TableCell>
                                <TableCell className={classes.tableHeader}>Dia com Mais Atendimentos</TableCell>
                                <TableCell className={classes.tableHeader}>Média de Atendimentos por Dia</TableCell>
                                <TableCell className={classes.tableHeader}>Tickets Pendentes</TableCell>
                                <TableCell className={classes.tableHeader}>Tickets Abertos</TableCell>
                                <TableCell className={classes.tableHeader}>Tickets Fechados</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{reportData.totalAttendances}</TableCell>
                                <TableCell>{formatDate(reportData.dayWithMostAttendances)}</TableCell>
                                <TableCell>{reportData.averageAttendancesPerDay}</TableCell>
                                <TableCell>{reportData.pendingTickets}</TableCell>
                                <TableCell>{reportData.openTickets}</TableCell>
                                <TableCell>{reportData.closedTickets}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    {/* Adicionando rolagem para a lista de contatos */}
                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome do Usuário</TableCell>
                                    <TableCell>ID do Ticket</TableCell>
                                    <TableCell>Número do Contato</TableCell>
                                    <TableCell>Data de Criação</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportData.contacts.map((contact, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{contact.contactName}</TableCell>
                                        <TableCell>{contact.ticketId}</TableCell>
                                        <TableCell>{contact.contactNumber}</TableCell>
                                        <TableCell>{formatDate(contact.lastUpdate)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            )}

            {/* Exibir os dados do relatório de WhatsApp */}
            {reportType === "whatsapp" && reportData.length > 0 && (
                <Paper className={classes.reportTable}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Relatório de WhatsApp
                    </Typography>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeader}>Nome do WhatsApp</TableCell>
                                <TableCell className={classes.tableHeader}>Total de Tickets</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((whatsapp, index) => (
                                <React.Fragment key={index}>
                                    <TableRow>
                                        <TableCell>{whatsapp.whatsappName}</TableCell>
                                        <TableCell>{whatsapp.totalTickets}</TableCell>
                                    </TableRow>
                                    {/* Exibir os tickets relacionados com rolagem */}
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>ID do Ticket</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>Nome do Contato</TableCell>
                                                            <TableCell>Número do Contato</TableCell>
                                                            <TableCell>Data de Criação</TableCell>
                                                            <TableCell>Última Atualização</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {whatsapp.tickets.map((ticket) => (
                                                            <TableRow key={ticket.ticketId}>
                                                                <TableCell>{ticket.ticketId}</TableCell>
                                                                <TableCell>
                                                                    {ticket.ticketStatus === "open"
                                                                        ? "Aberto"
                                                                        : ticket.ticketStatus === "closed"
                                                                            ? "Fechado"
                                                                            : "Pendente"}
                                                                </TableCell>
                                                                <TableCell>{ticket.contactName}</TableCell>
                                                                <TableCell>{ticket.contactNumber}</TableCell>
                                                                <TableCell>{new Date(ticket.createdAt).toLocaleString()}</TableCell>
                                                                <TableCell>{new Date(ticket.updatedAt).toLocaleString()}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            )}

        </MainContainer>
    );
};

export default Reports;
