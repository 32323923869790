import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import SpeedIcon from "@mui/icons-material/Speed";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonIcon from "@mui/icons-material/Person";
import TodayIcon from '@mui/icons-material/Today';
import BlockIcon from '@mui/icons-material/Block';
import DoneIcon from '@mui/icons-material/Done';

import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import { grey, blue } from "@mui/material/colors";
import { toast } from "react-toastify";

import Chart from "./Chart";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";

import CardCounter from "../../components/Dashboard/CardCounter";
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { isArray } from "lodash";

import useDashboard from "../../hooks/useDashboard";
import useCompanies from "../../hooks/useCompanies";

import { isEmpty } from "lodash";
import moment from "moment";
import { Facebook, Instagram, Telegram, WhatsApp } from "@material-ui/icons";

const theme = createTheme({
  spacing: 8, // define a unidade de espaçamento
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.4em'
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.1)'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
            outline: '1px solid slategrey'
          }
        }
      }
    }
  }
});

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: 272,
    overflowY: "auto",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.default,
  },
  selectContainer: {
    width: '100%',
    height: '40px',
    '& .MuiInputBase-root': {
      height: '40px',
      lineHeight: '40px',
    },
    '& .MuiInputLabel-root': {
      lineHeight: '40px',
    },
    '& input': {
      height: '40px',
      boxSizing: 'border-box',
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  icon: {
    fontSize: "32px",
    color: blue[700],
  },
  title: {
    fontSize: "16px",
    color: blue[700],
  },
  value: {
    fontSize: "24px",
    color: grey[800],
  },

  cardMinimal: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",  // Alinhando os itens em linha
    alignItems: "center",
    justifyContent: "flex-start",  // Alinhamento à esquerda
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: theme.shadows[6],
    },
    marginBottom: theme.spacing(1),
  },
  cardMinimalFilter: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",  // Alinhando os itens em linha
    alignItems: "center",
    justifyContent: "flex-start",  // Alinhamento à esquerda
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: theme.shadows[6],
    },
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: "45px",
    color: blue[700],
    marginRight: theme.spacing(2),  // Espaço entre o ícone e o texto
  },
  title: {
    fontSize: "16px",
    color: grey[800],
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  value: {
    fontSize: "20px",
    color: grey[900],
    fontWeight: 700,
  },
  fullWidth: {
    width: '100%', // Certifique-se de que o componente ocupa 100% da largura
  },
  width70: {
    width: '70%', // Certifique-se de que o componente ocupa 100% da largura
  },
  instagramGradient: {
    width: 45, // tamanho em px
    height: 45, // tamanho em px
    marginRight: theme.spacing(2),  // Espaço entre o ícone e o texto
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(to right, #833ab4, #fd1d1d, #fcb045)',
    mask: "url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path fill=\"%23FFF\" d=\"M7 2h10a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5zm3 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 1h1v2h-1V5zm-3 2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 2a3 3 0 1 1 0 6 3 3 0 0 1 0-6z\"/></svg>') center / contain no-repeat",
    WebkitMask: "url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path fill=\"%23FFF\" d=\"M7 2h10a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5zm3 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 1h1v2h-1V5zm-3 2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 2a3 3 0 1 1 0 6 3 3 0 0 1 0-6z\"/></svg>') center / contain no-repeat",
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [counters, setCounters] = useState({});
  const [channelCountWhatsApp, setChannelCountWhatsApp] = useState();
  const [channelCountFacebook, setChannelCountFacebook] = useState();
  const [channelCountInstagram, setChannelCountInstagram] = useState();
  const [channelCountTelegram, setChannelCountTelegram] = useState();
  const [attendants, setAttendants] = useState([]);
  const [filterType, setFilterType] = useState(1);
  const [period, setPeriod] = useState(0);
  const [companyDueDate, setCompanyDueDate] = useState();
  const [dateFrom, setDateFrom] = useState(
    moment("1", "D").format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const { find, channel } = useDashboard();
  const { finding } = useCompanies();
  useEffect(() => {
    async function firstLoad() {
      await fetchData();
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleChangePeriod(value) {
    setPeriod(value);
  }

  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  }

  async function fetchData() {
    setLoading(true);

    let params = {};

    if (period > 0) {
      params = {
        days: period,
      };
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = {
        ...params,
        date_from: moment(dateFrom).format("YYYY-MM-DD"),
      };
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = {
        ...params,
        date_to: moment(dateTo).format("YYYY-MM-DD"),
      };
    }

    if (Object.keys(params).length === 0) {
      toast.error("Parametrize o filtro");
      setLoading(false);
      return;
    }

    const data = await find(params);
    const channelWhats = await channel(companyId, "whatsapp");
    const channelFacebook = await channel(companyId, "facebook");
    const channelInstagram = await channel(companyId, "instagram");
    const channelTelegram = await channel(companyId, "telegram");

    setChannelCountWhatsApp(channelWhats.ticket_count);
    setChannelCountFacebook(channelFacebook.ticket_count);
    setChannelCountInstagram(channelInstagram.ticket_count);
    setChannelCountTelegram(channelTelegram.ticket_count);


    setCounters(data.counters);
    if (isArray(data.attendants)) {
      setAttendants(data.attendants);
    } else {
      setAttendants([]);
    }

    setLoading(false);
  }

  useEffect(() => {
    async function fetchData() {
      await loadCompanies();
    }
    fetchData();
  }, [])
  //let companyDueDate = localStorage.getItem("companyDueDate");
  //const companyDueDate = localStorage.getItem("companyDueDate").toString();
  const companyId = localStorage.getItem("companyId");
  const loadCompanies = async () => {
    setLoading(true);
    try {
      const companiesList = await finding(companyId);
      setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
    } catch (e) {
      // toast.error("Não foi possível carregar a lista de registros");
    }
    setLoading(false);
  };

  function formatTime(minutes) {
    return moment()
      .startOf("day")
      .add(minutes, "minutes")
      .format("HH[h] mm[m]");
  }

  function renderFilters() {
    if (filterType === 1) {
      return (
        <>
          <Paper elevation={3} className={clsx(classes.cardMinimalFilter, classes.fullWidth)}>
            <TodayIcon className={classes.icon} />
            <div className={classes.fullWidth}>
              <FormControl className={classes.selectContainer}>
                <TextField
                  label="Data Inicial"
                  type="date"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                  className={classes.selectContainer}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </div>
          </Paper>
          <Paper elevation={3} className={clsx(classes.cardMinimalFilter, classes.fullWidth)}>
            <TodayIcon className={classes.icon} />
            <div className={classes.fullWidth}>
              <FormControl className={classes.selectContainer}>
                <TextField
                  label="Data Final"
                  type="date"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                  className={classes.selectContainer}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </div>
          </Paper>
          <Grid item xs={12} className={classes.alignRight}>
            <ButtonWithSpinner
              loading={loading}
              onClick={() => fetchData()}
              variant="contained"
              color="primary"
            >
              Filtrar
            </ButtonWithSpinner>
          </Grid>
        </>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Paper elevation={3} className={clsx(classes.cardMinimalFilter, classes.fullWidth)}>
            <TodayIcon className={classes.icon} />
            <div className={classes.fullWidth}>
              <FormControl className={classes.selectContainer}>
                <InputLabel id="period-selector-label">Período</InputLabel>
                <Select
                  labelId="period-selector-label"
                  id="period-selector"
                  value={period}
                  onChange={(e) => handleChangePeriod(e.target.value)}
                >
                  <MenuItem value={0}>Nenhum selecionado</MenuItem>
                  <MenuItem value={3}>Últimos 3 dias</MenuItem>
                  <MenuItem value={7}>Últimos 7 dias</MenuItem>
                  <MenuItem value={15}>Últimos 15 dias</MenuItem>
                  <MenuItem value={30}>Últimos 30 dias</MenuItem>
                  <MenuItem value={60}>Últimos 60 dias</MenuItem>
                  <MenuItem value={90}>Últimos 90 dias</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Paper>
          <Grid item xs={12} className={classes.alignRight}>
            <ButtonWithSpinner
              loading={loading}
              onClick={() => fetchData()}
              variant="contained"
              color="primary"
            >
              Filtrar
            </ButtonWithSpinner>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="100%" className={classes.container}> {/* Removeu o ajuste de maxWidth */}
        <Grid container spacing={3} justifyContent="flex-end"> {/* Aumenta o espaçamento entre os itens */}
          {/* <Grid item xs={12}>
        <Paper elevation={3} className={classes.cardMinimal}>
          <TodayIcon className={classes.icon} />
          <div style={{ fontSize: 45, textAlign: "center" }} className={classes.cardContent}>
            <Typography className={classes.title}>
              Quantidade de atendimentos realizados hoje
            </Typography>
          </div>
        </Paper>
      </Grid> */}
          <Grid item xs={12} sm={6} md={3}> {/* Ajuste o tamanho para ser responsivo */}
            <Paper elevation={3} className={classes.cardMinimal}>
              <WhatsApp style={{ color: '#25D366', fontSize: 45 }} className={classes.icon} />
              <div className={classes.cardContent}>
                <Typography style={{ fontSize: 20 }} className={classes.title}>
                  WhatsApp: {loading ? <CircularProgress size={24} /> : <strong>{channelCountWhatsApp}</strong>}
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}> {/* Ajuste o tamanho para ser responsivo */}
            <Paper elevation={3} className={classes.cardMinimal}>
              <Facebook style={{ color: '#3b5998', fontSize: 45 }} className={classes.icon} />
              <div className={classes.cardContent}>
                <Typography style={{ fontSize: 20 }} className={classes.title}>
                  Facebook: {loading ? <CircularProgress size={24} /> : <strong>{channelCountFacebook}</strong>}
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}> {/* Ajuste o tamanho para ser responsivo */}
            <Paper elevation={3} className={classes.cardMinimal}>
              <div className={classes.instagramGradient}></div> {/* Usando o div com a máscara de gradiente */}
              <div className={classes.cardContent}>
                <Typography style={{ fontSize: 20 }} className={classes.title}>
                  Instagram: {loading ? <CircularProgress size={24} /> : <strong>{channelCountInstagram}</strong>}
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}> {/* Ajuste o tamanho para ser responsivo */}
            <Paper elevation={3} className={classes.cardMinimal}>
              <Telegram style={{ color: '#0088cc', fontSize: 45 }} className={classes.icon} />
              <div className={classes.cardContent}>
                <Typography style={{ fontSize: 20 }} className={classes.title}>
                  Telegram: {loading ? <CircularProgress size={24} /> : <strong>{channelCountTelegram}</strong>}
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}> {/* Aumenta o espaçamento interno */}
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}> {/* Adiciona um container interno para o espaçamento */}
                  <Grid item xs={12}>
                    <Paper className={classes.fixedHeightPaper}>
                      <Chart />
                    </Paper>
                  </Grid>
                  {attendants.length ? (
                    <Grid item xs={12}>
                      <TableAttendantsStatus
                        attendants={attendants}
                        loading={loading}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <Grid container spacing={1}> {/* Aumenta o espaçamento interno */}
                  <Grid item xs={12} md={12}> {/* Ajuste para duas colunas */}
                    <Paper elevation={3} className={classes.cardMinimal}>
                      <GroupIcon className={classes.icon} />
                      <div className={classes.cardContent}>
                        <Typography className={classes.title}>
                          Atd. Pendentes: {loading ? <CircularProgress size={24} /> : counters.supportPending}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}> {/* Ajuste para duas colunas */}
                    <Paper elevation={3} className={classes.cardMinimal}>
                      <GroupIcon className={classes.icon} />
                      <div className={classes.cardContent}>
                        <Typography className={classes.title}>
                          Atd. Acontecendo: {loading ? <CircularProgress size={24} /> : counters.supportHappening}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}> {/* Ajuste para duas colunas */}
                    <Paper elevation={3} className={classes.cardMinimal}>
                      <AssignmentIcon className={classes.icon} />
                      <div className={classes.cardContent}>
                        <Typography className={classes.title}>
                          Atd. Realizados:
                          {loading ? <CircularProgress size={24} /> : counters.supportFinished}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}> {/* Ajuste para duas colunas */}
                    <Paper elevation={3} className={classes.cardMinimal}>
                      <PersonIcon className={classes.icon} />
                      <div className={classes.cardContent}>
                        <Typography className={classes.title}>
                          Leads: {loading ? <CircularProgress size={24} /> : counters.leads}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}> {/* Ajuste para duas colunas */}
                    <Paper elevation={3} className={classes.cardMinimal}>
                      <SpeedIcon className={classes.icon} />
                      <div className={classes.cardContent}>
                        <Typography className={classes.title}>
                          T.M. de Atend: {loading ? <CircularProgress size={24} /> : formatTime(counters.avgSupportTime)}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}> {/* Ajuste para duas colunas */}
                    <Paper elevation={3} className={classes.cardMinimal}>
                      <SpeedIcon className={classes.icon} />
                      <div className={classes.cardContent}>
                        <Typography className={classes.title}>
                          T.M. de Espera: {loading ? <CircularProgress size={24} /> : formatTime(counters.avgWaitTime)}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={5}>
                <Grid container xs={12} md={12}>
                  <Grid item xs={12}>
                    <Paper elevation={3} className={clsx(classes.cardMinimalFilter, classes.fullWidth)}>
                      <TodayIcon className={classes.icon} />
                      <div className={classes.fullWidth}>
                        <FormControl className={classes.selectContainer}>
                          <InputLabel id="period-selector-label">Tipo de Filtro</InputLabel>
                          <Select
                            labelId="period-selector-label"
                            value={filterType}
                            onChange={(e) => handleChangeFilterType(e.target.value)}
                            className={classes.selectContainer}
                          >
                            <MenuItem value={1}>Filtro por Data</MenuItem>
                            <MenuItem value={2}>Filtro por Período</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    {renderFilters()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Container >
    </ThemeProvider >

  );
};

export default Dashboard;
