import React, { useEffect, useState } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import Title from "../Title";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import useSettings from "../../hooks/useSettings";
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import { Tabs, Tab } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { i18n } from "../../translate/i18n";


import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import usePlans from "../../hooks/usePlans/index.js";
import useCompanies from "../../hooks/useCompanies";


import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    DialogActions,
    CircularProgress,
    TextField,
    Switch,
    FormControlLabel,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
    },
    tab: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        width: "100%",
        "& .MuiTab-wrapper": {
            color: theme.palette.text.primary,
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "center",
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
        width: "100%",
    },
    cardAvatar: {
        fontSize: "55px",
        color: grey[500],
        backgroundColor: "#ffffff",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardTitle: {
        fontSize: "18px",
        color: blue[700],
    },
    cardSubtitle: {
        color: grey[600],
        fontSize: "14px",
    },
    alignRight: {
        textAlign: "right",
    },
    fullWidth: {
        width: "100%",
    },
    selectContainer: {
        width: "100%",
        textAlign: "left",
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    // Adicione um estilo para a box vermelha
    redBox: {
        backgroundColor: "#ffcccc", // Definindo a cor de fundo vermelha
        padding: theme.spacing(2), // Adicionando um espaçamento interno
        marginBottom: theme.spacing(2), // Adicionando margem inferior para separar do conteúdo abaixo
    },
}));



export default function Remarketing(props) {
    const { settings, scheduleTypeChanged } = props;
    const classes = useStyles();

    // Estados individuais para cada formulário
    const [formData1, setFormData1] = useState({
        remarketing1minutes: "",
        remarketing1times: "",
        remarketing1message: ""
    });

    const [formData2, setFormData2] = useState({
        remarketing2minutes: "",
        remarketing2times: "",
        remarketing2message: ""
    });

    const [formData3, setFormData3] = useState({
        remarketing3minutes: "",
        remarketing3times: "",
        remarketing3message: ""
    });


    const { finder, getPlanCompany } = usePlans();
    const { find, updateSchedules } = useCompanies();

    const [remarketingType, setRemarketingType] = useState("");
    const [loadingRemarketingType, setLoadingRemarketingType] = useState(false);
    const [remarketingTypeUpdated, setRemarketingTypeUpdated] = useState(false);



    const { update } = useSettings();

    useEffect(() => {
        if (Array.isArray(settings) && settings.length) {
            const remarketing1minutes = settings.find((s) => s.key === "remarketing1minutes");
            const remarketing1times = settings.find((s) => s.key === "remarketing1times");
            const remarketing1message = settings.find((s) => s.key === "remarketing1message");


            // Atualiza somente remarketing1Minutes no estado formData1
            setFormData1(prevState => ({
                ...prevState,
                remarketing1minutes: remarketing1minutes?.value || "",
                remarketing1times: remarketing1times?.value || "",
                remarketing1message: remarketing1message?.value || ""
            }));

            const remarketing2minutes = settings.find((s) => s.key === "remarketing2minutes");
            const remarketing2times = settings.find((s) => s.key === "remarketing2times");
            const remarketing2message = settings.find((s) => s.key === "remarketing2message");

            // Atualiza somente remarketing1Minutes no estado formData1
            setFormData2(prevState => ({
                ...prevState,
                remarketing2minutes: remarketing2minutes?.value || "",
                remarketing2times: remarketing2times?.value || "",
                remarketing2message: remarketing2message?.value || ""
            }));


            const remarketing3minutes = settings.find((s) => s.key === "remarketing3minutes");
            const remarketing3times = settings.find((s) => s.key === "remarketing3times");
            const remarketing3message = settings.find((s) => s.key === "remarketing3message");


            // Atualiza somente remarketing1Minutes no estado formData1
            setFormData3(prevState => ({
                ...prevState,
                remarketing3minutes: remarketing3minutes?.value || "",
                remarketing3times: remarketing3times?.value || "",
                remarketing3message: remarketing3message?.value || ""
            }));


        }
    }, [settings]);

    async function handleChangeRemarketing() {
        if (!remarketingTypeUpdated) return;
        setLoadingRemarketingType(true);
        await update({
            key: "remarketing",
            value: remarketingType,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingRemarketingType(false);
        setRemarketingTypeUpdated(false);
    }


    const handleSaveForm1 = async (values, actions) => {
        try {
            await update({
                key: "remarketing1minutes",
                value: values.remarketing1minutes,
            });
            await update({
                key: "remarketing1times",
                value: values.remarketing1times,
            });
            await update({
                key: "remarketing1message",
                value: values.remarketing1message,
            });
            toast.success("Dados da Primeira Opção de Remarketing salvos com sucesso!");
        } catch (error) {
            toastError(error);
        }
        actions.setSubmitting(false);
    };

    const handleSaveForm2 = async (values, actions) => {
        try {
            await update({
                key: "remarketing2minutes",
                value: values.remarketing2minutes,
            });
            await update({
                key: "remarketing2times",
                value: values.remarketing2times,
            });
            await update({
                key: "remarketing2message",
                value: values.remarketing2message,
            });
            toast.success("Dados da Segunda Opção de Remarketing salvos com sucesso!");
        } catch (error) {
            toastError(error);
        }
        actions.setSubmitting(false);
    };

    const handleSaveForm3 = async (values, actions) => {
        try {
            await update({
                key: "remarketing3minutes",
                value: values.remarketing3minutes,
            });
            await update({
                key: "remarketing3times",
                value: values.remarketing3times,
            });
            await update({
                key: "remarketing3message",
                value: values.remarketing3message,
            });
            toast.success("Dados da Terceira Opção de Remarketing salvos com sucesso!");
        } catch (error) {
            toastError(error);
        }
        actions.setSubmitting(false);
    };

    return (
        <>
            <Grid spacing={3} container style={{ marginBottom: 10 }}>
                <Grid container spacing={2}> {/* Define um Grid container aqui com um espaçamento */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.redBox} variant="outlined">
                            <Typography variant="body1" style={{ textAlign: 'center' }}>
                                <strong>Aviso Importante:</strong> Lembre-se de configurar a opção de remarketing no <strong>Fila</strong> ou <strong>Conexão</strong>
                            </Typography>
                        </Paper>

                    </Grid>
                    {/* Primeiro item de Grid para o primeiro formulário */}
                    <Grid item xs={6} sm={6} md={4}> {/* Altere para ocupar metade da largura */}
                        <Formik initialValues={formData1} onSubmit={handleSaveForm1} enableReinitialize={true}>
                            {({ isSubmitting }) => (
                                <Form>
                                    <DialogContent dividers>

                                        <h3 style={{ textAlign: 'center' }}>{i18n.t("Primeira Opção de Remarketing")}</h3>
                                        <p style={{ textAlign: 'center' }}>{i18n.t("Preencha os dados para que o sistema possa fazer o remarketing nos locais configurados")}</p>
                                        <Grid spacing={2} container>
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("A cada quantos minutos ?")}
                                                    fullWidth
                                                    name="remarketing1minutes"
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("Enviar mensagem quantas vezes ?")}
                                                    fullWidth
                                                    name="remarketing1times"
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="number"
                                                />
                                            </Grid>
                                        </Grid>
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("Mensagem do primeiro Remaketing")}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                name="remarketing1message"
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>


                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {i18n.t("Salvar")}
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>


                    </Grid>

                    {/* Segundo item de Grid para o segundo formulário */}
                    <Grid item xs={6} sm={6} md={4}>
                        <Formik initialValues={formData2} onSubmit={handleSaveForm2} enableReinitialize={true}>
                            {({ isSubmitting }) => (
                                <Form>
                                    <DialogContent dividers>

                                        <h3 style={{ textAlign: 'center' }}>{i18n.t("Segunda Opção de Remarketing")}</h3>
                                        <p style={{ textAlign: 'center' }}>{i18n.t("Preencha os dados para que o sistema possa fazer o remarketing nos locais configurados")}</p>
                                        <Grid spacing={2} container>
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("A cada quantos minutos ?")}
                                                    fullWidth
                                                    name="remarketing2minutes"
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("Enviar mensagem quantas vezes ?")}
                                                    fullWidth
                                                    name="remarketing2times"
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="number"
                                                />
                                            </Grid>
                                        </Grid>
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("Mensagem do segundo Remaketing")}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                name="remarketing2message"
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>


                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {i18n.t("Salvar")}
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </Grid>

                    {/* Terceiro item de Grid para o segundo formulário */}
                    <Grid item xs={6} sm={6} md={4}>
                        <Formik initialValues={formData3} onSubmit={handleSaveForm3} enableReinitialize={true}>
                            {({ isSubmitting }) => (
                                <Form>
                                    <DialogContent dividers>

                                        <h3 style={{ textAlign: 'center' }}>{i18n.t("Terceira Opção de Remarketing")}</h3>
                                        <p style={{ textAlign: 'center' }}>{i18n.t("Preencha os dados para que o sistema possa fazer o remarketing nos locais configurados")}</p>
                                        <Grid spacing={2} container>
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("A cada quantos minutos ?")}
                                                    fullWidth
                                                    name="remarketing3minutes"
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("Enviar mensagem quantas vezes ?")}
                                                    fullWidth
                                                    name="remarketing3times"
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="number"
                                                />
                                            </Grid>
                                        </Grid>
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("Mensagem do terceiro Remaketing")}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                name="remarketing3message"
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>


                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {i18n.t("Salvar")}
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>
            <ToastContainer />
        </>
    );
}