import React, { useEffect, useState } from "react";
import JsSIP from "jssip";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { Button, TextField, Grid } from "@material-ui/core";

const SIPConfig = () => {
    const [ua, setUa] = useState(null); // Armazena a instância do UserAgent
    const [status, setStatus] = useState("Desconectado");
    const [callStatus, setCallStatus] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("035998685274"); // Número de telefone padrão
    const [wsError, setWsError] = useState(""); // Captura detalhes do erro do WebSocket
    const [username, setUsername] = useState("1010"); // Usuário SIP padrão
    const [password, setPassword] = useState("101010"); // Senha SIP padrão
    const [port, setPort] = useState("8089"); // Porta WebSocket configurada

    // Desabilitar o debug do JsSIP
    JsSIP.debug.disable('JsSIP:*');

    // Função para conectar ao WebSocket
    const connectToWebSocket = (portToUse) => {
        const socketURL = `wss://homologationvoip.chatlinkbrasil.com.br:${portToUse}/ws`;
        const socket = new JsSIP.WebSocketInterface(socketURL);
        const configuration = {
            sockets: [socket],
            uri: `sip:${username}@homologationvoip.chatlinkbrasil.com.br`,
            password: password,  // Certifique-se de que a senha está correta
            session_timers: false,
            media_constraints: { audio: true, video: false },
            register: true,  // Habilitar o registro SIP
        };

        const userAgent = new JsSIP.UA(configuration);

        userAgent.on("connected", () => {
            setStatus(`Conectado ao WebSocket (Porta ${portToUse})`);
        });

        userAgent.on("disconnected", (e) => {
            setStatus(`WebSocket desconectado (Porta ${portToUse})`);
            setWsError(`Erro WebSocket: Código ${e.code}, Motivo: ${e.reason || "N/A"} na porta ${portToUse}`);
        });

        userAgent.on("registered", () => {
            setStatus(`Registrado com sucesso no SIP (Porta ${portToUse})`);
        });

        userAgent.on("registrationFailed", (e) => {
            setStatus(`Falha ao registrar SIP: ${e.cause} (Porta ${portToUse})`);
        });

        userAgent.on("connectionFailed", (e) => {
            setStatus(`Falha ao conectar: ${e.cause} (Porta ${portToUse})`);
        });

        setUa(userAgent); // Armazena a instância do UA para conectar e desconectar manualmente
        userAgent.start();  // Inicia o processo de registro
    };

    useEffect(() => {
        if (ua) {
            ua.stop(); // Se uma instância de UA já existir, pare-a antes de criar uma nova
        }
        connectToWebSocket(port); // Conecta ao WebSocket com a porta configurada
    }, [port, username, password]);

    const handleConnect = () => {
        if (ua) {
            ua.start();
            ua.register();
        }
    };

    const handleDisconnect = () => {
        if (ua) {
            ua.stop();
            setStatus("Desconectado");
        }
    };

    const handlePortChange = (e) => {
        setPort(e.target.value); // Muda a porta dinamicamente, se necessário
    };

    const handleCall = () => {
        if (ua && phoneNumber) {
            const session = ua.call(`sip:${phoneNumber}@homologationvoip.chatlinkbrasil.com.br`, {
                mediaConstraints: { audio: true, video: false },
            });
            setCallStatus(`Ligando para ${phoneNumber}...`);

            session.on("accepted", () => {
                setCallStatus(`Chamada com ${phoneNumber} aceita`);
            });

            session.on("ended", () => {
                setCallStatus("Chamada finalizada");
            });

            session.on("failed", () => {
                setCallStatus("Chamada falhou");
            });
        }
    };

    return (
        <MainContainer>
            <MainHeader>
                <Title>Configuração SIP</Title>
            </MainHeader>

            <Grid container spacing={3}>
                {/* Campo para Número de Telefone */}
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Número de Telefone"
                        variant="outlined"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </Grid>

                {/* Campo para Nome de Usuário SIP */}
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Usuário SIP"
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Grid>

                {/* Campo para Senha SIP */}
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Senha SIP"
                        type="password"
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Grid>

                {/* Campo para Porta */}
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Porta WebSocket"
                        variant="outlined"
                        value={port}
                        onChange={handlePortChange}
                    />
                </Grid>

                {/* Botões de Conectar e Desconectar */}
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleConnect}
                        disabled={status.includes("Conectado")}
                    >
                        Conectar
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleDisconnect}
                        disabled={status === "Desconectado"}
                    >
                        Desconectar
                    </Button>
                </Grid>

                {/* Botão para Fazer Chamada */}
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="default"
                        onClick={handleCall}
                        disabled={status !== `Conectado ao WebSocket (Porta ${port})` || !phoneNumber}
                    >
                        Ligar
                    </Button>
                </Grid>

                {/* Status da Conexão */}
                <Grid item xs={12}>
                    <p>Status do SIP: {status}</p>
                </Grid>

                {/* Erro do WebSocket (se houver) */}
                {wsError && (
                    <Grid item xs={12}>
                        <p style={{ color: "red" }}>{wsError}</p>
                    </Grid>
                )}

                {/* Status da Chamada */}
                <Grid item xs={12}>
                    <p>Status da Chamada: {callStatus}</p>
                </Grid>
            </Grid>
        </MainContainer>
    );
};

export default SIPConfig;
