import React, { useState, useEffect, useMemo } from "react";
import i18n from "i18next";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

import { ptBR } from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import TranslationContext from './hooks/useTranslations/translationContext'
import ColorModeContext from "./layout/themeContext";
import api from "./services/api";
import Routes from "./routes";

const queryClient = new QueryClient();

const App = () => {
    const [locale, setLocale] = useState();
    const [pageTitle, setPageTitle] = useState('');  // Novo estado para o título
    //const appColorLocalStorage = localStorage.getItem("primaryColorLight") || localStorage.getItem("primaryColorDark") || "#065183";
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const preferredTheme = window.localStorage.getItem("preferredTheme");
    const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light");
    const [primaryColorLight, setPrimaryColorLight] = useState('' || "#065183");
    const [primaryColorDark, setPrimaryColorDark] = useState('' || "#065183");

    // Fetching the title from the backend and updating document.title
    useEffect(() => {
        const fetchTitle = async () => {
            try {
                const response = await api.get("/setting/titlePage");
                const pageTitle = response?.data?.value;
                setPageTitle(pageTitle);
                document.title = pageTitle; // Altera o título da página
            } catch (error) {
                console.error('Error retrieving viewregister', error);
            }
            try {
                const response = await api.get("/setting/primaryColorLight");
                const primaryColorLight = response?.data?.value;
                setPrimaryColorLight(primaryColorLight);
                //console.log(primaryColorLight)
            } catch (error) {
                console.error('Error retrieving viewregister', error);
            }
        };

        fetchTitle();
    }, []);

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    const newMode = prevMode === "light" ? "dark" : "light";
                    window.localStorage.setItem("preferredTheme", newMode); // Persistindo o tema no localStorage
                    return newMode;
                });
            },
            setPrimaryColorLight,
            setPrimaryColorDark,
            mode,
        }),
        []
    );

    const theme = createTheme(
        {
            scrollbarStyles: {
                "&::-webkit-scrollbar": {
                    width: '8px',
                    height: '8px',
                },
                "&::-webkit-scrollbar-thumb": {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: primaryColorLight,
                },
            },
            scrollbarStylesSoft: {
                "&::-webkit-scrollbar": {
                    width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: mode === "light" ? "#F3F3F3" : "#333333",
                },
            },
            palette: {
                type: mode,
                primary: { main: primaryColorLight },
                textPrimary: mode === "light" ? primaryColorLight : "#333",
                borderPrimary: mode === "light" ? primaryColorLight : "#333",
                dark: { main: mode === "light" ? "#333333" : "#F3F3F3" },
                light: { main: mode === "light" ? "#F3F3F3" : "#333333" },
                tabHeaderBackground: mode === "light" ? "#EEE" : "#333",
                optionsBackground: mode === "light" ? "#fafafa" : "#333",
                options: mode === "light" ? "#fafafa" : "#666",
                fontecor: mode === "light" ? primaryColorLight : "#fff",
                fancyBackground: mode === "light" ? "#fafafa" : "#333",
                bordabox: mode === "light" ? "#eee" : "#333",
                newmessagebox: mode === "light" ? "#eee" : "#333",
                inputdigita: mode === "light" ? "#fff" : "#333",
                contactdrawer: mode === "light" ? "#fff" : "#333",
                announcements: mode === "light" ? "#ededed" : "#333",
                login: mode === "light" ? "#fff" : "#1C1C1C",
                announcementspopover: mode === "light" ? "#fff" : "#333",
                chatlist: mode === "light" ? "#eee" : "#333",
                boxlist: mode === "light" ? "#ededed" : "#333",
                boxchatlist: mode === "light" ? "#ededed" : "#333",
                total: mode === "light" ? "#fff" : "#222",
                messageIcons: mode === "light" ? "grey" : "#F3F3F3",
                inputBackground: mode === "light" ? primaryColorDark : "#333",
                barraSuperior: mode === "light" ? primaryColorLight : "#666",
                boxticket: mode === "light" ? "#EEE" : "#333",
                campaigntab: mode === "light" ? "#ededed" : "#333",
            },
            mode,
        },
        locale
    );

    // translation context methods
    const [defaultLg, setDefaultLg] = useState(() => {
        const language = localStorage.getItem('@logyca:language');

        if (language) {
            return language;
        }
        return 'pt';
    });

    const useLanguage = React.useMemo(
        () => ({
            handleSetLanguage: (lg) => {
                localStorage.removeItem('@logyca:language');
                localStorage.setItem('@logyca:language', lg);
                i18n.changeLanguage(lg)
                setDefaultLg(lg)
            },
            defaultLg,
        }),
        [defaultLg]
    );

    useEffect(() => {

        const i18nlocale = localStorage.getItem("i18nextLng");
        const browserLocale =
            i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

        if (browserLocale === "ptBR") {
            setLocale(ptBR);
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem("preferredTheme", mode);
    }, [mode]);



    return (
        <TranslationContext.Provider value={{ useLanguage }}>
            <ColorModeContext.Provider value={{ colorMode }}>
                <ThemeProvider theme={theme}>
                    <QueryClientProvider client={queryClient}>
                        <Routes />
                    </QueryClientProvider>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </TranslationContext.Provider>
    );
};

export default App;
