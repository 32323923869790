import React, { useEffect, useState, useCallback } from "react";
import {
    Grid,
    Paper,
    Typography,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import {
    Edit,
    DeleteOutline,
    SignalCellularConnectedNoInternet0Bar,
    SignalCellularConnectedNoInternet2Bar,
    SignalCellular4Bar,
    CropFree,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import api from "../../services/api";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import WhatsAppModal from "../../components/WhatsAppModal";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket"; // Importa o serviço de socket

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.fancyBackground,
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        textAlign: "center",
    },
    paper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.options,
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
        transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
        "&:hover": {
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.24), 0 4px 8px rgba(0, 0, 0, 0.22)",
        },
        minHeight: 280,
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        fontWeight: "bold",
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
    },
    loading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 150,
    },
}));

export default function WhatsAppClientes() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false); // Controla o estado de carregamento
    const [connections, setConnections] = useState([]); // Armazena as conexões
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [qrModalOpen, setQrModalOpen] = useState(false);
    const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
    const [selectedConnection, setSelectedConnection] = useState(null);
    const [confirmModalInfo, setConfirmModalInfo] = useState({
        action: "",
        title: "",
        message: "",
        connectionId: "",
    });

    // Função para buscar as conexões iniciais
    useEffect(() => {
        const fetchConnections = async () => {
            setLoading(true);
            try {
                const { data } = await api.get("/whatsapp-admin/");

                // Ordena os dados por companyId em ordem crescente
                const sortedData = data.sort((a, b) => a.companyId - b.companyId);

                setConnections(sortedData);
            } catch (error) {
                toastError("Erro ao buscar conexões de WhatsApp.");
            }
            setLoading(false);
        };

        fetchConnections();
    }, []);


    // Configuração do socket para escutar atualizações
    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        // Escutando atualizações de conexões de WhatsApp
        socket.on(`company-${companyId}-whatsapp`, (data) => {
            if (data.action === "update") {
                setConnections((prevConnections) => {
                    return prevConnections.map((connection) =>
                        connection.id === data.whatsapp.id ? data.whatsapp : connection
                    );
                });
            }
        });

        // Cleanup quando o componente for desmontado
        return () => {
            socket.disconnect();
        };
    }, []);

    const handleOpenQrModal = (connection) => {
        setSelectedConnection(connection);
        setQrModalOpen(true);
    };

    const handleCloseQrModal = useCallback(() => {
        setSelectedConnection(null);
        setQrModalOpen(false);
    }, []);

    const handleEditConnection = (connection) => {
        setSelectedConnection(connection);
        setWhatsAppModalOpen(true);
    };

    const handleCloseWhatsAppModal = useCallback(() => {
        setWhatsAppModalOpen(false);
        setSelectedConnection(null);
    }, []);

    const handleOpenConfirmationModal = (action, connectionId) => {
        setConfirmModalInfo({
            action,
            title: action === "delete" ? "Excluir Conexão" : "Desconectar Conexão",
            message: action === "delete"
                ? "Tem certeza que deseja excluir esta conexão?"
                : "Tem certeza que deseja desconectar?",
            connectionId,
        });
        setConfirmModalOpen(true);
    };

    const handleSubmitConfirmationModal = async () => {
        try {
            if (confirmModalInfo.action === "delete") {
                await api.delete(`/whatsapp/${confirmModalInfo.connectionId}`);
                setConnections((prevConnections) =>
                    prevConnections.filter((c) => c.id !== confirmModalInfo.connectionId)
                );
                toast.success("Conexão excluída com sucesso!");
            } else if (confirmModalInfo.action === "disconnect") {
                await api.post(`/whatsapp/disconnect/${confirmModalInfo.connectionId}`);
                toast.success("Conexão desconectada com sucesso!");
            }
        } catch (error) {
            toastError("Erro ao processar a ação.");
        } finally {
            setConfirmModalOpen(false);
        }
    };

    const renderStatusToolTips = (connection) => {
        return (
            <div>
                {connection.status === "DISCONNECTED" && (
                    <Tooltip title="Desconectado">
                        <SignalCellularConnectedNoInternet0Bar color="secondary" />
                    </Tooltip>
                )}
                {connection.status === "CONNECTED" && (
                    <Tooltip title="Conectado">
                        <SignalCellular4Bar style={{ color: "green" }} />
                    </Tooltip>
                )}
                {connection.status === "PAIRING" && (
                    <Tooltip title="Emparelhando">
                        <SignalCellularConnectedNoInternet2Bar color="secondary" />
                    </Tooltip>
                )}
            </div>
        );
    };

    return (
        <div className={classes.container}>
            <Typography variant="h6" className={classes.sectionTitle}>
                WhatsApp - Clientes
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            Todas Conexões
                        </Typography>

                        {loading ? (
                            <div className={classes.loading}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Empresa</TableCell>
                                        <TableCell align="center">Canal</TableCell>
                                        <TableCell align="center">Nome</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {connections.map((connection) => (
                                        <TableRow key={connection.id}>
                                            <TableCell align="center">
                                                {connection.company?.name || "Sem empresa"} {/* Exibe o nome da empresa ou "Sem empresa" se não houver */}
                                            </TableCell>

                                            <TableCell align="center">WhatsApp</TableCell>
                                            <TableCell align="center">{connection.name}</TableCell>
                                            <TableCell align="center">
                                                {renderStatusToolTips(connection)}
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleEditConnection(connection)}
                                                >
                                                    <Edit />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        handleOpenConfirmationModal("delete", connection.id)
                                                    }
                                                >
                                                    <DeleteOutline />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            <ConfirmationModal
                title={confirmModalInfo.title}
                open={confirmModalOpen}
                onClose={() => setConfirmModalOpen(false)}
                onConfirm={handleSubmitConfirmationModal}
            >
                {confirmModalInfo.message}
            </ConfirmationModal>

            <QrcodeModal
                open={qrModalOpen}
                onClose={handleCloseQrModal}
                whatsAppId={selectedConnection?.id}
            />

            <WhatsAppModal
                open={whatsAppModalOpen}
                onClose={handleCloseWhatsAppModal}
                whatsAppId={selectedConnection?.id}
            />
        </div>
    );
}
