import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import useSettings from "../../hooks/useSettings";
import { toast } from 'react-toastify';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@material-ui/core/Button'; // Adiciona o botão

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.fancyBackground, // Usando a cor de fundo do tema
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        textAlign: 'center',
    },
    paper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.options, // Usando a cor de fundo do papel no tema
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
        transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
        '&:hover': {
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.24), 0 4px 8px rgba(0, 0, 0, 0.22)"
        },
        minHeight: 280,
    },
    selectContainer: {
        width: "100%",
        textAlign: "left",
        alignSelf: "flex-start"
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.primary.main, // Usando a cor primária do tema para o botão
        color: theme.palette.primary.contrastText,
    }
}));

export default function Functions(props) {
    const { settings } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false); // Controla o estado de carregamento geral

    const [enableSigameType, setEnableSigameType] = useState(false);
    const [sigametelType, setSigameTelType] = useState("");
    const [sigametimeType, setSigameTimeType] = useState("");

    const { update } = useSettings();

    useEffect(() => {
        if (Array.isArray(settings) && settings.length) {
            const sigametelType = settings.find((s) => s.key === "sigametel");
            if (sigametelType) {
                setSigameTelType(sigametelType.value);
            }
            const sigametimeType = settings.find((s) => s.key === "sigametime");
            if (sigametimeType) {
                setSigameTimeType(sigametimeType.value);
            }
            const enableSigameTypeSetting = settings.find((s) => s.key === "enablesigametel");
            if (enableSigameTypeSetting) {
                setEnableSigameType(enableSigameTypeSetting.value === "true" || enableSigameTypeSetting.value === true);
            }
        }
    }, [settings]);

    // Função para salvar todos os campos
    async function handleSave() {
        setLoading(true);

        try {
            await update({ key: "sigametel", value: sigametelType });
            await update({ key: "sigametime", value: sigametimeType });
            await update({ key: "enablesigametel", value: enableSigameType });

            toast.success("Configurações atualizadas com sucesso.");
        } catch (error) {
            toast.error("Erro ao atualizar configurações.");
        }

        setLoading(false);
    }

    return (
        <div className={classes.container}>
            <Typography variant="h6" className={classes.sectionTitle}>
                FUNÇÕES
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <Paper className={classes.paper}>

                        <Typography variant="h6" className={classes.sectionTitle}>
                            SIGA-ME
                        </Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={enableSigameType}  // Controla o estado do Switch
                                        onChange={(e) => setEnableSigameType(e.target.checked)}  // Captura o estado do Switch
                                    />
                                }
                                label="Ativar ?"
                            />
                        </FormGroup>

                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="sigametel"
                                name="sigametel"
                                type="number"
                                margin="dense"
                                label="Telefone para aviso"
                                variant="outlined"
                                value={sigametelType}
                                onChange={(e) => setSigameTelType(e.target.value)}
                            />
                        </FormControl>

                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="sigametime"
                                name="sigametime"
                                type="number"
                                margin="dense"
                                label="Após quantos minutos enviar o Aviso?"
                                variant="outlined"
                                value={sigametimeType}
                                onChange={(e) => setSigameTimeType(e.target.value)}
                            />
                        </FormControl>

                        {/* Botão para salvar todas as alterações */}
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={handleSave}
                            disabled={loading} // Desabilita o botão durante o carregamento
                        >
                            {loading ? "Salvando..." : "Salvar"}
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
