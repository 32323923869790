import React from "react";
import { Modal, Backdrop, Fade, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%',
        height: '80%', // Ajusta o tamanho para o iframe
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    iframe: {
        width: '100%',
        height: '100%',
        border: 'none', // Remove a borda padrão do iframe
    },
    button: {
        marginTop: theme.spacing(2),
    }
}));

const InvoiceUrlModal = ({ open, onClose, invoiceUrl }) => {
    const classes = useStyles();

    if (!invoiceUrl) {
        return null; // Se não houver URL, não exibe o modal
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    {/* Exibe o iframe com a URL do boleto */}
                    <iframe
                        src={invoiceUrl}
                        title="Boleto de Pagamento"
                        className={classes.iframe}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={onClose}
                    >
                        Fechar
                    </Button>
                </div>
            </Fade>
        </Modal>
    );
};

export default InvoiceUrlModal;
