import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import {
    makeStyles,
    FormControl,
    Switch,
    FormControlLabel,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
    InputAdornment
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { head } from "lodash";
import { ToastContainer, toast } from 'react-toastify';
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Title from "../Title";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import useSettings from "../../hooks/useSettings";
import { grey, blue } from "@material-ui/core/colors";
import { Tabs, Tab } from "@material-ui/core";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { Colorize, AttachFile, Delete } from "@material-ui/icons";
import ColorPicker from "../ColorPicker";
import ColorModeContext from "../../layout/themeContext";
import ColorBoxModal from "../ColorBoxModal/index.js";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    mainPaper: {
        width: "100%",
        flex: 1,
        padding: theme.spacing(2),
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
    },
    tab: {
        background: "#f2f5f3",
        borderRadius: 4,
        width: "100%",
        "& .MuiTab-wrapper": {
            color: "#128c7e"
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "center"
        }


    },
    selectContainer: {
        width: "100%",
        textAlign: "left",
        alignSelf: "flex-start"
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
        width: "100%",
    },
    cardAvatar: {
        fontSize: "55px",
        color: grey[500],
        backgroundColor: "#ffffff",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardTitle: {
        fontSize: "18px",
        color: blue[700],
    },
    cardSubtitle: {
        color: grey[600],
        fontSize: "14px",
    },
    alignRight: {
        textAlign: "right",
    },
    fullWidth: {
        width: "100%",
    },
    selectContainer: {
        width: "100%",
        textAlign: "left",
    },
    buttonContainer: {
        textAlign: "right",
        padding: theme.spacing(1),
    },
    fullWidth: {
        width: "100%",
    },
    fileInput: {
        background: "red",
    },
    fileInputLabel: {
        display: "inline-block",
        backgroundColor: "#7c7c7c",
        color: "#fff",
        padding: "8px 16px",
        borderRadius: "4px",
        cursor: "pointer",
        "& input": {
            display: "none",
        },
    },
}));

const Uploader = (props) => {
    const [file, setFile] = useState(null);
    const [uploaded, setUploaded] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const { profile } = user;
    const history = useHistory();
    const [selectedFileName, setSelectedFileName] = useState('');
    const { settings } = props;
    const [trial, settrial] = useState('3');
    const [blockuser, setBlockUser] = useState('3');
    const [titlePage, setTitlePage] = useState("");
    const [loadingtrial, setLoadingtrial] = useState(false);
    const [loadingblock, setLoadingBlock] = useState(false);
    const [loadingTitlePage, setLoadingTitlePage] = useState(false);

    const [viewregister, setviewregister] = useState('disabled');
    const [loadingviewregister, setLoadingviewregister] = useState(false);
    const [asaasToken, setAsaasToken] = useState("");
    const [loadingAsaasToken, setLoadingAsaasToken] = useState(false);
    const [numeroSuporte, setNumeroSuporte] = useState("");
    const [loadingnNumeroSuporte, setLoadingNumeroSuporte] = useState(false);
    const [enableAsaasGateway, setEnableAsaasGateway] = useState(false);

    const [loadingDefaultGateway, setLoadingDefaultGateway] = useState(false);
    const [defaultGateway, setDefaultGateway] = useState("");

    const [loadingColor, setLoadingColor] = useState(false);
    const [color, setColor] = useState("");

    const { colorMode } = useContext(ColorModeContext);
    const [primaryColorLightModalOpen, setPrimaryColorLightModalOpen] = useState(false);

    const [settingsLoaded, setSettingsLoaded] = useState({});
    const { update } = useSettings();

    function updateSettingsLoaded(key, value) {

        if (key === 'primaryColorLight' || key === 'primaryColorDark' || key === 'appName') {
            localStorage.setItem(key, value);
        };
        const newSettings = { ...settingsLoaded };
        newSettings[key] = value;
        setSettingsLoaded(newSettings);
    }

    async function handleSaveSetting(key, value) {

        await update({
            key,
            value,
        });
        updateSettingsLoaded(key, value);
        toast.success("Operação atualizada com sucesso.");
    }
    // trava para nao acessar pagina que não pode 
    useEffect(() => {
        async function fetchData() {
            if (!user.super) {
                toast.error("Sem permissão para acessar!");
                setTimeout(() => {
                    history.push(`/`)
                }, 500);
            }
        }
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (Array.isArray(settings) && settings.length) {

            const primaryColorLight = settings.find((s) => s.key === "primaryColorLight");
            if (primaryColorLight) {
                setColor(primaryColorLight.value)
            }
            const blockuser = settings.find((s) => s.key === 'blockuser');
            if (blockuser) {
                setBlockUser(blockuser.value);
            }

            const titlePage = settings.find((s) => s.key === 'titlePage');
            if (titlePage) {
                setTitlePage(titlePage.value);
            }

            const trial = settings.find((s) => s.key === 'trial');
            if (trial) {
                settrial(trial.value);
            }

            const viewregister = settings.find((s) => s.key === 'viewregister');
            if (viewregister) {
                setviewregister(viewregister.value);
            }

            const asaasToken = settings.find((s) => s.key === 'gatewayAsaasToken');
            if (asaasToken) {
                setAsaasToken(asaasToken.value);
            }

            const defaultGateway = settings.find((s) => s.key === 'defaultGateway');
            if (defaultGateway) {
                setDefaultGateway(defaultGateway.value);
            }

            const numeroSuporte = settings.find((s) => s.key === 'numeroSuporte');
            if (numeroSuporte) {
                setNumeroSuporte(numeroSuporte.value);
            }

            const enableAsaasGateway = settings.find((s) => s.key === 'enableAsaasGateway');
            if (enableAsaasGateway) {
                const isEnabled = enableAsaasGateway.value === 'true';
                setEnableAsaasGateway(isEnabled);
            }
        }
    }, [settings]);

    async function handleviewregister(value) {
        setviewregister(value);
        setLoadingviewregister(true);
        await update({
            key: 'viewregister',
            value,
        });
        toast.success('Operação atualizada com sucesso.');
        setLoadingviewregister(false);
    }

    async function handletrial(value) {
        settrial(value);
        setLoadingtrial(true);
        await update({
            key: 'trial',
            value,
        });
        toast.success('Operação atualizada com sucesso.');
        setLoadingtrial(false);
    }

    async function handleBlockUser(value) {
        setBlockUser(value);
        setLoadingBlock(true);
        await update({
            key: 'blockuser',
            value,
        });
        toast.success('Operação atualizada com sucesso.');
        setLoadingBlock(false);
    }

    async function handleTitlePage(value) {
        setTitlePage(value);
        setLoadingTitlePage(true);
        await update({
            key: 'titlePage',
            value,
        });
        toast.success('Operação atualizada com sucesso.');
        setLoadingTitlePage(false);
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const allowedTypes = ["image/png", "image/x-icon", "image/svg+xml"];

        if (selectedFile && allowedTypes.includes(selectedFile.type)) {
            setFile(selectedFile);
            setSelectedFileName(selectedFile.name);
        } else {
            setFile(null);
            setSelectedFileName(null);
            toast.error("Use somente arquivos em formato PNG, ICO ou SVG!");
        }
    };


    async function hadleNumeroSuporte(value) {
        setNumeroSuporte(value);
        setLoadingNumeroSuporte(true);
        await update({
            key: "numeroSuporte",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingNumeroSuporte(false);
    }


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!file) {
            toast.warn("Escolha um arquivo!");
            return;
        }

        if (!selectedOption) {
            toast.warn("Escolha um destino!");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await api.post(`/settings/media-upload?ref=${selectedOption}`, formData);

            if (response.data.mensagem === 'Arquivo Anexado') {
                setUploaded(true);
                toast.success("Arquivo enviado com sucesso!");
                window.location.reload();

            }
        } catch (error) {
            //console.log(error);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        setLoadingAsaasToken(true);

        try {
            await update({
                key: "gatewayAsaasToken",
                value: asaasToken,
            });

            await update({
                key: "enableAsaasGateway",
                value: enableAsaasGateway,
            });

            toast.success("Configuração do Gateway atualizada com sucesso.");
        } catch (error) {
            console.error("Falha ao atualizar as configurações do Gateway:", error);
            toast.error("Erro ao atualizar as configurações.");
        } finally {
            setLoadingAsaasToken(false);
        }
    }

    const handleFormSubmitTitle = async (e) => {
        e.preventDefault();

        setLoadingTitlePage(true);

        try {
            await update({
                key: "titlePage",
                value: titlePage,
            });

            toast.success("Titulo da atualizado com sucesso.");
            window.location.reload();
        } catch (error) {
            console.error("Falha ao atualizar as configurações do Titulo:", error);
            toast.error("Erro ao atualizar as configurações.");
        } finally {
            setLoadingTitlePage(false);
        }
    }

    const handleSwitchChange = (event) => {
        setEnableAsaasGateway(event.target.checked);
    };

    async function handleChangeDefaultGateway(value) {
        setDefaultGateway(value);
        setLoadingDefaultGateway(true);
        await update({
            key: "defaultGateway",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingDefaultGateway(false);
    }

    async function handleChangeColor(value) {
        setColor(value);
        setLoadingColor(true);
        await update({
            key: "defaultGateway",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingDefaultGateway(false);
    }

    return (
        <>
            <Grid spacing={3} container>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    className={classes.tab}
                    style={{
                        marginBottom: 20,
                        marginTop: 20
                    }}
                >
                    <Tab label="Logotipos / Ícones" />
                </Tabs>

                <form onSubmit={handleSubmit} className={classes.fullWidth}>
                    <Grid container spacing={3} justify="center">
                        <Grid item xs={12} sm={4} md={4}>
                            <div className={classes.appLogoDarkPreviewDiv}>
                                <img
                                    src={`${process.env.REACT_APP_BACKEND_URL}/public/logotipos/signup.png`}
                                    className={classes.appLogoDarkPreviewImg}
                                    alt="Registro"
                                    style={{ width: '100%', height: 'auto' }} // Tamanho 100% para ajustar ao grid
                                />
                                <Typography variant="caption" display="block" align="center">
                                    Tela de Registro
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <div className={classes.appLogoLightPreviewDiv}>
                                <img
                                    src={`${process.env.REACT_APP_BACKEND_URL}/public/logotipos/login.png`}
                                    className={classes.appLogoLightPreviewImg}
                                    alt="Login"
                                    style={{ width: '100%', height: 'auto' }}
                                />
                                <Typography variant="caption" display="block" align="center">
                                    Tela de Login
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <div className={classes.appLogoFaviconPreviewDiv}>
                                <img
                                    src={`${process.env.REACT_APP_BACKEND_URL}/public/logotipos/interno.png`}
                                    className={classes.appLogoFaviconPreviewImg}
                                    alt="Interno"
                                    style={{ width: '100%', height: 'auto' }}
                                />
                                <Typography variant="caption" display="block" align="center">
                                    Logotipo Interno
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', marginTop: 20 }}>
                        <FormControl className={classes.selectContainer}>
                            <InputLabel id="selectOption-label">Escolha uma opção:</InputLabel>
                            <Select
                                labelId="selectOption-label"
                                value={selectedOption}
                                onChange={handleOptionChange}
                                style={{ marginTop: 15, marginBottom: 15 }}
                            >
                                <MenuItem value="signup">Tela de Registro</MenuItem>
                                <MenuItem value="login">Tela de Login</MenuItem>
                                <MenuItem value="interno">Logotipo Interno</MenuItem>
                                <MenuItem value="favicon">Favicon.Ico</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', marginTop: 20 }}>
                        <FormControl className={classes.fullWidth}>
                            <label className={classes.fileInputLabel}>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className={classes.fileInput}
                                    style={{ marginTop: 15, marginBottom: 15 }}
                                />
                                {selectedFileName ? selectedFileName : 'Escolher imagem em PNG'}
                            </label>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', marginTop: 20 }}>
                        <ButtonWithSpinner
                            type="submit"
                            className={`${classes.fullWidth} ${classes.button}`}
                            variant="contained"
                            color="primary"
                        >
                            ENVIAR ARQUIVO
                        </ButtonWithSpinner>
                    </Grid>
                </form>

            </Grid>
            <Grid spacing={3} container>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    className={classes.tab}
                    style={{
                        marginBottom: 20,
                        marginTop: 20
                    }}
                >
                    <Tab label="Configurações White Label" /></Tabs>
                <Grid container spacing={2} alignItems="center">
                    {/* Primeiro Elemento: Campo de Seleção de Cor */}
                    <Grid item xs={12} sm={4} md={4}>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="primary-color-light-field"
                                label="Cor Primária Modo Claro"
                                variant="standard"
                                value={color || ""}
                                onClick={() => setPrimaryColorLightModalOpen(true)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <div
                                                style={{ backgroundColor: settingsLoaded.primaryColorLight }}
                                                className={classes.colorAdorment}
                                            ></div>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <IconButton
                                            size="small"
                                            color="default"
                                            onClick={() => setPrimaryColorLightModalOpen(true)}
                                        >
                                            <Colorize />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </FormControl>
                        <ColorBoxModal
                            open={primaryColorLightModalOpen}
                            handleClose={() => setPrimaryColorLightModalOpen(false)}
                            onChange={(color) => {
                                handleSaveSetting("primaryColorLight", `#${color.hex}`);
                                colorMode.setPrimaryColorLight(`#${color.hex}`);
                            }}
                            currentColor={settingsLoaded.primaryColorLight}
                        />
                    </Grid>

                    {/* Segundo Elemento: Título da Página */}
                    <Grid item xs={12} sm={4} md={4}>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="titlePage"
                                name="titlePage"
                                margin="dense"
                                label="Titulo da Página"
                                variant="outlined"
                                value={titlePage}
                                onChange={(e) => setTitlePage(e.target.value)}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    {/* Terceiro Elemento: Botão Salvar */}
                    <Grid item xs={12} sm={4} md={4}>
                        <form onSubmit={handleFormSubmitTitle} className={classes.fullWidth}>
                            <ButtonWithSpinner
                                type="submit"
                                className={`${classes.fullWidth} ${classes.button}`}
                                variant="contained"
                                color="primary"
                            >
                                Salvar
                            </ButtonWithSpinner>
                        </form>
                    </Grid>
                </Grid>


                <Grid xs={12} sm={12} md={3} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id='viewregister-label'>
                            Registro (Inscrição) Visível?
                        </InputLabel>
                        <Select
                            labelId='viewregister-label'
                            value={viewregister}
                            onChange={async (e) => {
                                handleviewregister(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Não</MenuItem>
                            <MenuItem value={'enabled'}>Sim</MenuItem>
                        </Select>
                        <FormHelperText>
                            {loadingviewregister && 'Atualizando...'}
                        </FormHelperText>
                    </FormControl>
                </Grid>

                <Grid xs={12} sm={12} md={3} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id='trial-label'>Tempo de Trial?</InputLabel>
                        <Select
                            labelId='trial-label'
                            value={trial}
                            onChange={async (e) => {
                                handletrial(e.target.value);
                            }}
                        >
                            <MenuItem value={'1'}>1</MenuItem>
                            <MenuItem value={'2'}>2</MenuItem>
                            <MenuItem value={'3'}>3</MenuItem>
                            <MenuItem value={'4'}>4</MenuItem>
                            <MenuItem value={'5'}>5</MenuItem>
                            <MenuItem value={'6'}>6</MenuItem>
                            <MenuItem value={'7'}>7</MenuItem>
                        </Select>
                        <FormHelperText>
                            {loadingtrial && 'Atualizando...'}
                        </FormHelperText>
                    </FormControl>
                </Grid>

                <Grid xs={12} sm={12} md={3} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id='block-label'>Tempo de bloqueio após vencimento?</InputLabel>
                        <Select
                            labelId='block-label'
                            value={blockuser}
                            onChange={async (e) => {
                                handleBlockUser(e.target.value);
                            }}
                        >
                            <MenuItem value={'1'}>1</MenuItem>
                            <MenuItem value={'2'}>2</MenuItem>
                            <MenuItem value={'3'}>3</MenuItem>
                            <MenuItem value={'4'}>4</MenuItem>
                            <MenuItem value={'5'}>5</MenuItem>
                            <MenuItem value={'6'}>6</MenuItem>
                            <MenuItem value={'7'}>7</MenuItem>
                        </Select>
                        <FormHelperText>
                            {loadingblock && 'Atualizando...'}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="numeroSuporte"
                            name="numeroSuporte"
                            margin="dense"
                            label="Número de suporte"
                            variant="outlined"
                            value={numeroSuporte}
                            onChange={async (e) => {
                                hadleNumeroSuporte(e.target.value);
                            }}
                            fullWidth
                        />

                    </FormControl>
                </Grid>
            </Grid>
            <Grid spacing={3} container>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    className={classes.tab}
                    style={{
                        marginBottom: 20,
                        marginTop: 20
                    }}
                >
                    <Tab label="Confguração de Gateway" />
                </Tabs>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl className={classes.selectContainer}>
                        <Select
                            id="defaultGateway"
                            name="defaultGateway"
                            margin="dense"
                            variant="outlined"
                            defaultValue=""
                            value={defaultGateway}
                            onChange={async (e) => {
                                handleChangeDefaultGateway(e.target.value);
                            }}
                        >
                            <MenuItem value="nenhum">Nao usar Financeiro</MenuItem>
                            <MenuItem value="asaas">Asaas</MenuItem>
                            <MenuItem value="efi">Efi/Gerencianet</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    className={classes.tab}
                    style={{
                        marginBottom: 20,
                        marginTop: 20
                    }}
                >
                    <Tab label="Configuração Gateway Asaas" />
                </Tabs>
                <Grid item xs={12} sm={12} md={12}>
                    <form onSubmit={handleFormSubmit} className={classes.fullWidth}>
                        <Grid container alignItems="center" style={{ display: 'flex', marginBottom: 15, marginLeft: 10 }}>
                            <Grid item xs={3} sm={3} md={3}>
                                <FormControl component="fieldset" className={classes.selectContainer}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={enableAsaasGateway}
                                                onChange={handleSwitchChange}
                                                name="enableAsaasGateway"
                                                color="primary"
                                            />
                                        }
                                        label="Ativar Gateway"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={9} sm={9} md={9}>
                                <FormControl className={classes.selectContainer}>
                                    <TextField
                                        id="gatewayAsaasToken"
                                        name="gatewayAsaasToken"
                                        margin="dense"
                                        label="Token"
                                        variant="outlined"
                                        value={asaasToken}
                                        onChange={(e) => setAsaasToken(e.target.value)}
                                        fullWidth
                                    />

                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ButtonWithSpinner
                                type="submit"
                                className={`${classes.fullWidth} ${classes.button}`}
                                variant="contained"
                                color="primary"
                            >
                                Salvar
                            </ButtonWithSpinner>
                        </Grid>
                    </form>
                </Grid>

            </Grid >
        </>
    );
};

export default Uploader;