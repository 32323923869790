import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import api from "../../services/api";
import MainContainer from "../../components/MainContainer";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import toastError from "../../errors/toastError";
import InvoiceUrlModal from "../../components/InvoiceUrlModal";
import { socketConnection } from "../../services/socket";
import useAuth from "../../hooks/useAuth.js";
import useCompanies from "../../hooks/useCompanies";
import useSettings from "../../hooks/useSettings";
import ConfirmationModal from "../../components/ConfirmationModal/index.js";
import { useHistory } from "react-router-dom";
import EditInvoiceModal from "../../components/EditInvoiceModal"; // Certifique-se de importar o modal

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_INVOICES") {
    const invoices = action.payload;
    const newUsers = [];

    invoices.forEach((user) => {
      const userIndex = state.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) {
        state[userIndex] = user;
      } else {
        newUsers.push(user);
      }
    });

    return [...state, ...newUsers];
  }

  if (action.type === "UPDATE_USERS") {
    const user = action.payload;
    const userIndex = state.findIndex((u) => u.id === user.id);

    if (userIndex !== -1) {
      state[userIndex] = user;
      return [...state];
    } else {
      return [user, ...state];
    }
  }

  if (action.type === "DELETE_USER") {
    const userId = action.payload;

    const userIndex = state.findIndex((u) => u.id === userId);
    if (userIndex !== -1) {
      state.splice(userIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Invoices = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [invoices, dispatch] = useReducer(reducer, []);
  const [selectedInvoiceUrl, setSelectedInvoiceUrl] = useState(null);
  const [invoiceUrlModalOpen, setInvoiceUrlModalOpen] = useState(false);
  const [companyId, setCompanyId] = useState(null); // Estado para armazenar o ID da empresa atual
  const { list, save, update, remove, removeinvoice } = useCompanies();
  const history = useHistory();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [company, setCompany] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [settings, setSettings] = useState({});
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);

  const { getCurrentUserInfo } = useAuth();
  const { find, updateSchedules } = useCompanies();
  const { getAll: getAllSettings } = useSettings();
  useEffect(() => {
    async function findData() {
      setLoading(true);
      try {
        const user = await getCurrentUserInfo();
        setCurrentUser(user);
      } catch (e) {
        toast.error(e);
      }

      setLoading(false);
    }
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  // Conexão com o socket.io
  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-payment`, (data) => {
      const { action, company } = data;

      if (action === "paid") {
        toast.success("Pagamento recebido, fechando modal...");
        setInvoiceUrlModalOpen(false); // Fecha o modal quando o pagamento é recebido
      }
    });

    // Desconecta o socket ao desmontar o componente
    return () => {
      socket.disconnect();
    };
  }, [companyId]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchInvoices = async () => {
        try {
          const { data } = await api.get("/invoices/all", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_INVOICES", payload: data });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchInvoices();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const rowStyle = (record) => {
    const hoje = moment(moment()).format("DD/MM/yyyy");
    const vencimento = moment(record.dueDate).format("DD/MM/yyyy");
    var diff = moment(vencimento, "DD/MM/yyyy").diff(moment(hoje, "DD/MM/yyyy"));
    var dias = moment.duration(diff).asDays();
    if (dias < 0 && record.status !== "paid") {
      return { backgroundColor: "#ffbcbc9c" };
    }
  };

  const rowStatus = (record) => {
    const hoje = moment(moment()).format("DD/MM/yyyy");
    const vencimento = moment(record.dueDate).format("DD/MM/yyyy");
    var diff = moment(vencimento, "DD/MM/yyyy").diff(moment(hoje, "DD/MM/yyyy"));
    var dias = moment.duration(diff).asDays();
    const status = record.status;
    if (status === "paid") {
      return "Pago";
    }
    if (dias < 0) {
      return "Vencido";
    } else {
      return "Em Aberto";
    }
  };

  // Função para abrir o modal e passar a URL da fatura
  const handleOpenInvoiceUrlModal = (invoiceUrl, id) => {
    if (invoiceUrl) {
      setSelectedInvoiceUrl(invoiceUrl);
      setInvoiceUrlModalOpen(true);
      setCompanyId(id); // Armazena o ID da empresa atual
    }
  };

  // Função para fechar o modal
  const handleCloseInvoiceUrlModal = () => {
    setSelectedInvoiceUrl(null);
    setInvoiceUrlModalOpen(false);
    setCompanyId(null); // Reseta o ID da empresa quando o modal for fechado
  };


  // Função para salvar as alterações
  const handleSaveEdit = async (updatedInvoice) => {
    setLoading(true);
    try {
      await api.put(`/invoices/${updatedInvoice.id}`, updatedInvoice);
      const updatedInvoiceWithCompanyName = {
        ...updatedInvoice,
        companyName: selectedInvoice.companyName,
        invoiceUrl: selectedInvoice.invoiceUrl,
      };

      dispatch({ type: "UPDATE_USERS", payload: updatedInvoiceWithCompanyName });
      toast.success("Fatura atualizada com sucesso.");
      handleCloseEditModal();
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  };


  // Função para abrir o modal de edição
  const handleOpenEditModal = (invoice) => {
    setSelectedInvoice(invoice);
    setEditModalOpen(true);
  };

  // Função para fechar o modal de edição
  const handleCloseEditModal = () => {
    setSelectedInvoice(null);
    setEditModalOpen(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await api.delete(`/invoices/${id}`);
      toast.success("Fatura excluída com sucesso!");

      // Fechar o modal, se estiver aberto
      setInvoiceUrlModalOpen(false);

      // Atualizar a lista de faturas
      setPageNumber(1); // Reiniciar para carregar novamente
      setSearchParam(""); // Resetar qualquer pesquisa anterior
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  };


  /* const handleDelete = async (id) => {
    setLoading(true);
    try {
      await api.delete(`/invoices/${id}`);
      // Atualiza o estado local removendo a fatura excluída
      dispatch({ type: "DELETE_USER", payload: id });
      toast.success("Fatura deletada com sucesso.");
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  }; */

  if (currentUser.super) {
    return (
      <MainContainer>
        <EditInvoiceModal
          open={editModalOpen}
          onClose={handleCloseEditModal}
          invoice={selectedInvoice} // Passa a fatura selecionada
          onSave={handleSaveEdit}
        />
        <InvoiceUrlModal
          open={invoiceUrlModalOpen}
          onClose={handleCloseInvoiceUrlModal}
          invoiceUrl={selectedInvoiceUrl}
        />
        <Paper
          className={classes.mainPaper}
          variant="outlined"
          onScroll={handleScroll}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Empresa</TableCell>
                <TableCell align="center">Detalhes</TableCell>
                <TableCell align="center">Valor</TableCell>
                <TableCell align="center">Data Venc.</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {


                  invoices.map((invoice) => (
                    <TableRow style={rowStyle(invoice)} key={invoice.id}>

                      <TableCell align="center">{invoice.companyName}</TableCell>
                      <TableCell align="center">{invoice.detail}</TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {Number(invoice.value).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </TableCell>
                      <TableCell align="center">
                        {moment(invoice.dueDate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {rowStatus(invoice)}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={() => handleOpenEditModal(invoice)}
                        >
                          Editar
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleDelete(invoice.id)} // Passa o ID da fatura
                        >
                          Deletar
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={() => window.open(invoice.invoiceUrl, '_blank')} // Abre a URL em uma nova aba
                        >
                          Ver Fatura
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {loading && <TableRowSkeleton columns={6} />}
              </>
            </TableBody>
          </Table>
        </Paper>
      </MainContainer>
    );
  } else {
    return (
      <MainContainer>
        <InvoiceUrlModal
          open={invoiceUrlModalOpen}
          onClose={handleCloseInvoiceUrlModal}
          invoiceUrl={selectedInvoiceUrl}
        />
        <Paper
          className={classes.mainPaper}
          variant="outlined"
          onScroll={handleScroll}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Id</TableCell>
                <TableCell align="center">Detalhes</TableCell>
                <TableCell align="center">Valor</TableCell>
                <TableCell align="center">Data Venc.</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {invoices.map((invoice) => (
                  <TableRow style={rowStyle(invoice)} key={invoice.id}>
                    <TableCell align="center">{invoice.id}</TableCell>
                    <TableCell align="center">{invoice.detail}</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="center">
                      {invoice.value.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {moment(invoice.dueDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="center">
                      {rowStatus(invoice)}
                    </TableCell>
                    <TableCell align="center">
                      {rowStatus(invoice) !== "Pago" ? (
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleOpenInvoiceUrlModal(invoice.invoiceUrl, invoice.id)}  // Passa a URL da fatura e o ID da empresa ao modal
                        >
                          PAGAR
                        </Button>

                      ) : (
                        <Button size="small" variant="outlined">
                          PAGO
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={4} />}
              </>
            </TableBody>
          </Table>
        </Paper>
      </MainContainer>
    );
  }
}

export default Invoices;
